import React from 'react'
import AdminPanelLeftBar from './AdminPanelLeftBar'
import AdminPanelNavbar from './AdminPanelNavbar'
import { Outlet } from 'react-router-dom'


function AdminPanel() {
  
  return (
    <div className="w-full h-screen flex overflow-hidden pt-16">
      
      {/* Sol tarafta sabitlenen LeftBar */}
      <div className="w-64 h-full bg-white z-50">
        <AdminPanelLeftBar />
      </div>
      
      {/* Sağ taraftaki ana içerik alanı */}
      <div className="flex-1 h-full flex flex-col">
        {/* Navbar üstte, sol taraftaki menünün yanında yer alacak */}
        <div className="w-full z-50">
          <AdminPanelNavbar />
        </div>
        
        {/* İçerik alanı Navbar'ın altında kalan bölgeyi kaplar */}
        <div className="flex-1 relative overflow-y-auto p-6 bg-gray-300"> 
          <Outlet /> 
            
        </div>
      </div>
    </div>
  )
}

export default AdminPanel
