import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../home/Home';
import Login from '../auth/Login';
import NotFound from '../404/NotFound';
import Register from '../auth/Register';
import AdminPanel from '../admin/AdminPanel';
import AllEvents from '../admin/AllEvents';
import AddEvent from '../addevent/AddEvent';
import ForgotPassword from '../auth/ForgotPassword';
import Users from '../users/Users';

import AllCategories from '../categories/AllCategories';
import FilterPage from '../../components/FilterPage/FilterPage';
import FilteredEventsPage from '../../components/FilterPage/FilteredEventsPage';
import EventDetail from '../events/EventDetail';
import { useAuth } from '../../contexts/authContext';
import PrivacyPolicy from '../privacypolicy/PrivacyPolicy';
import Blog from '../blog/Blog';
import ArtistsList from '../artist/ArtistList';
import ArtistDetail from '../artist/ArtistDetail';
import CalendarPage from '../calendar/CalendarPage';
import SSS from '../../components/sss/SSS'
import PasswordReset from '../auth/PasswordReset';
import AdminPanelArtists from '../artist/AdminPanelArtists';
import SehirSecim from '../city/SehirSecim';
import Etkinlikler from '../city/Etkinlikler';
import CookiePolicy from '../cerezpolitikası/CookiePolicy';
import Footer from '../footer/Footer';

function AppRoutes() {
  const { userLoggedIn, role } = useAuth();

  // Determine the initial redirection
  const redirectPath = userLoggedIn ? (role === 'admin' ? '/admin' : '/') : '/login';

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route 
        path="/login" 
        element={!userLoggedIn ? <Login /> : <Navigate to={redirectPath} />} 
      />
      <Route 
        path="/register" 
        element={!userLoggedIn ? <Register /> : <Navigate to={redirectPath} />} 
      />
      <Route 
        path="/blog" 
        element={<Blog /> } 
      />
      
      <Route 
        path="/takvim" 
        element={<CalendarPage /> } 
      />
 
      
      <Route 
        path="/sss" 
        element={<SSS/> } 
      />
      
       <Route path="/artist" element={<ArtistsList />} />
       <Route path="/artist/:id" element={<ArtistDetail />} />
      <Route 
        path="/privacy-policy" 
        element={<PrivacyPolicy/>} 
      />
      <Route 
        path="/forgot-password" 
        element={!userLoggedIn ? <ForgotPassword /> : <Navigate to={redirectPath} />} 
      />
      <Route path="/sehirler" element={<SehirSecim />} />
      <Route path="/sehirler/:city" element={<Etkinlikler />} />
      <Route 
        path="/filter" 
        element={<FilterPage />} 
      />
      <Route 
        path="/event/:id" 
        element={<EventDetail />} 
      />
      <Route 
        path="/filtered-events" 
        element={<FilteredEventsPage />} 
      />
      <Route 
          path="/password-reset" 
          element={<PasswordReset /> } 
        />
      <Route 
          path="/cookie-policy" 
          element={<div className='bg-gray-200 min-h-screen  pt-24'><h1 className="text-3xl font-bold text-center mb-8">Çerez Politikası | Türkiye Etkinlikleri</h1>
<CookiePolicy /><Footer/> </div>} 
        />
      
      <Route 
        path="/admin" 
        element={userLoggedIn ? (role === 'admin' ? <AdminPanel /> : <Navigate to="/" />) : <Navigate to="/login" />} 
      >
        <Route 
          path="allevents" 
          element={role === 'admin' ? <AllEvents /> : <Navigate to="/" />} 
        />
        <Route 
          path="addevent" 
          element={role === 'admin' ? <AddEvent /> : <Navigate to="/" />} 
        />
        <Route 
          path="allartists" 
          element={role === 'admin' ? <AdminPanelArtists /> : <Navigate to="/" />} 
        />
        

        <Route 
          path="users" 
          element={role === 'admin' ? <Users /> : <Navigate to="/" />} 
        />
        <Route 
          path="allartist" 
          element={role === 'admin' ? <ArtistsList /> : <Navigate to="/" />} 
        />
        <Route 
          path="all-categories" 
          element={role === 'admin' ? <AllCategories /> : <Navigate to="/" />} 
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
