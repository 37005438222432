
import Popup from '../addevent/AddEventPopup';
import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { db } from '../../firebase/Firebase';
import { collection, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { GrMapLocation, GrLocation } from "react-icons/gr";
import { Timestamp } from 'firebase/firestore'; // Timestamp import
import { MdOutlineDateRange } from "react-icons/md";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import ArchiveEventsButton from '../archive/ArchiveEventsButton';
import React from 'react';

const etkinliklerRef = collection(db, 'etkinlikler');

export default function AllEvents() {
  const [snapshot, loadingEtkinlikler] = useCollection(etkinliklerRef);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false); // Güncelleme formu durumu
  const [currentEventId, setCurrentEventId] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null); // Güncellenen etkinlik
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false); // Güncelleme yükleme durumu

  
  const deleteEvent = async () => {
    if (currentEventId) {
      setLoading(true);
      try {
        const eventDoc = doc(db, 'etkinlikler', currentEventId);
        await deleteDoc(eventDoc);
        setOpen(false);
        setCurrentEventId(null);
        toast.success('Etkinlik başarıyla silindi.');
      } catch (error) {
        console.error("Etkinlik silinirken hata oluştu: ", error);
        toast.error('Etkinlik silinirken hata oluştu.');
      } finally {
        setLoading(false);
      }
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const [visibleCount, setVisibleCount] = useState(6); 
const [showMore, setShowMore] = useState(true);
  const handleShowMore = () => {
    if (snapshot.docs.length > visibleCount) {
      setVisibleCount(prev => prev + 6);
      if (snapshot.docs.length <= visibleCount + 6) {
        setShowMore(false);
      }
    }
  };
  
  const handleShowLess = () => {
    if (visibleCount > 6) {
      setVisibleCount(prev => prev - 6);
      setShowMore(true);
    }
  };
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const updateEvent = async () => {
    if (currentEventId && currentEvent) {
      setUpdateLoading(true);
      try {
        const eventDoc = doc(db, 'etkinlikler', currentEventId);

        // Tarih değerini kontrol et
        const updatedDate = currentEvent.date ? currentEvent.date.toDate() : null;

        // Tarih değerinin geçerli olup olmadığını kontrol et
        if (updatedDate && !isNaN(updatedDate.getTime())) {
          await updateDoc(eventDoc, {
            ...currentEvent,
            date: Timestamp.fromDate(updatedDate) // Tarihi Firestore Timestamp nesnesine dönüştür
          });
          toast.success('Etkinlik başarıyla güncellendi.');
        } else {
          toast.error('Etkinlik güncellenemedi.');
          // Geçersiz tarih durumunda kullanıcıya bilgi verebilir veya varsayılan bir tarih değeri kullanabilirsiniz.
        }

        setUpdateOpen(false);
        setCurrentEventId(null);
        setCurrentEvent(null);
      } finally {
        setUpdateLoading(false);
      }
    }
  };




  if (loading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }
  if (updateLoading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={updateLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }
  if (loadingEtkinlikler) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loadingEtkinlikler}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }

  if (!snapshot || snapshot.empty) {
    return <div className='flex justify-between items-center'>Gösterilecek etkinlik yok.<button
    onClick={togglePopup}
    className="inline-flex items-center justify-center rounded bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
  >
    Yeni Etlinlik Ekle
  </button>{showPopup && <Popup onClose={togglePopup} setShowPopup={setShowPopup} />}</div>;
  }

  return (
    <div className='w-full'>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Etkinlikler</h2>
        <div className='flex items-center justify-end gap-2'>
        <button
          onClick={togglePopup}
          className="inline-flex items-center justify-center rounded bg-green-500 px-4 py-2 text-sm font-semibold text-white hover:bg-green-600"
        >
          Yeni Etlinlik Ekle
        </button>
        <ArchiveEventsButton/>
        </div>
      </div>
      {showPopup && <Popup onClose={togglePopup} setShowPopup={setShowPopup} />}

      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      {snapshot.docs.slice(0, visibleCount).map((doc) => {
          const etkinlik = doc.data();
          const id = doc.id;

          return (
            <article key={id} className="flex flex-col items-start rounded-xl bg-gray-50 shadow-md">
              {etkinlik.imageUrl ? (
                <div className='h-52 rounded-t-xl w-full overflow-hidden'>
                  <img className='w-full h-full object-cover' src={etkinlik.imageUrl} alt="" />
                </div>
              ) : (
                <div className='w-full h-full object-cover'>
                  <img src={etkinlik.imageUrl} alt="" />
                </div>
              )}
              <div className='p-4 w-full'>
                <div className="flex items-center gap-x-4 text-sm">
                  <time dateTime={etkinlik.date && etkinlik.date.toDate().toLocaleDateString()} className="text-gray-500 flex items-center justify-center gap-1">
                    <MdOutlineDateRange />{etkinlik.date && new Timestamp(etkinlik.date.seconds, etkinlik.date.nanoseconds).toDate().toLocaleDateString()}
                  </time>
                  <button
                    className="relative z-10 rounded-full bg-cyan-500 px-3 py-1 font-semibold text-white hover:bg-cyan-600"
                  >
                    {etkinlik.category}
                  </button>
                </div>
                <h3 className="mt-3 text-lg font-bold leading-6 text-gray-900 group-hover:text-gray-600">

                  {etkinlik.title}
                </h3>
                <div className="group relative">
                  <p className="mt-5 line-clamp-3 text-md leading-6 text-gray-600">{etkinlik.description}</p>
                </div>
                <div className='text-sm mt-3 text-gray-500 flex gap-4 justify-start items-center'>
                  <p className='flex justify-start items-center gap-1'><GrLocation />{etkinlik.city}</p>
                  <p className='flex justify-start items-center gap-1'><GrMapLocation />{etkinlik.venue}</p>
                </div>
                <hr className='mt-4' />

                <div className='flex justify-start items-center gap-2'>
                <button
                  onClick={() => { setOpen(true); setCurrentEventId(id); }}
                  className="mt-4 text-sm rounded relative z-30 font-semibold bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                >
                  Kaldır
                </button>
                <button
                  onClick={() => { setUpdateOpen(true); setCurrentEventId(id); setCurrentEvent(etkinlik); }}
                  className="mt-4 text-sm rounded relative z-30 font-semibold bg-blue-500 px-3 py-1 text-white hover:bg-blue-600"
                >
                  Güncelle
                </button>
                </div>

              </div>
            </article>
          );
        })}
      </div>
      <div className="mt-4 flex justify-center gap-2">
  {visibleCount > 6 && (
    <button
      onClick={handleShowLess}
      className="inline-flex items-center justify-center rounded bg-white border border-cyan-500 px-4 py-2 text-sm font-semibold text-cyan-500 shadow-sm hover:bg-cyan-500 hover:text-white"
    >
      Daha Az Göster
    </button>
  )}
  {showMore && (
    <button
      onClick={handleShowMore}
      className="inline-flex items-center justify-center rounded bg-cyan-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-600"
    >
      Daha Fazla Göster
    </button>
  )}
</div>
      {/* Onay Penceresi */}
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white p-6  ">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Etkinliği Sil
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Bu etkinliği silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 pb-6 gap-2 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={deleteEvent}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
                >
                  Kaldır
                </button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  İptal
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Güncelleme Penceresi */}
      <Dialog open={updateOpen} onClose={() => setUpdateOpen(false)} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white p-6">
                <div className="sm:flex sm:items-start">
                  <div className="text-left w-full mt-0">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                      Etkinliği Güncelle
                    </DialogTitle>
                    <div className="mt-2">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateEvent();
                        }}
                        className="space-y-4"
                      >
                        <div>
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                            Başlık
                          </label>
                          <input
                            id="title"
                            type="text"
                            value={currentEvent?.title || ''}
                            onChange={(e) => setCurrentEvent(prev => ({ ...prev, title: e.target.value }))}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div>
                          <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Tarih
                          </label>
                          <input
                            id="date"
                            type="date"
                            value={currentEvent?.date ? new Timestamp(currentEvent.date.seconds, currentEvent.date.nanoseconds).toDate().toISOString().split('T')[0] : ''}
                            onChange={(e) => {
                              const newDate = new Date(e.target.value);
                              // Tarih verisinin geçerli olup olmadığını kontrol et
                              if (!isNaN(newDate.getTime())) {
                                setCurrentEvent(prev => ({
                                  ...prev,
                                  date: Timestamp.fromDate(newDate) // Tarihi Firestore Timestamp nesnesine dönüştür
                                }));
                              } else {
                                console.error("Geçersiz tarih verisi:", e.target.value);
                                setCurrentEvent(prev => ({
                                  ...prev,
                                  date: null // Geçersiz tarih durumunda null olarak ayarla
                                }));
                              }
                            }}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />


                        </div>

                        <div>
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Açıklama
                          </label>
                          <textarea
                            id="description"
                            value={currentEvent?.description || ''}
                            onChange={(e) => setCurrentEvent(prev => ({ ...prev, description: e.target.value }))}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div>
                          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                            Şehir
                          </label>
                          <input
                            id="city"
                            type="text"
                            value={currentEvent?.city || ''}
                            onChange={(e) => setCurrentEvent(prev => ({ ...prev, city: e.target.value }))}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div>
                          <label htmlFor="venue" className="block text-sm font-medium text-gray-700">
                            Mekan
                          </label>
                          <input
                            id="venue"
                            type="text"
                            value={currentEvent?.venue || ''}
                            onChange={(e) => setCurrentEvent(prev => ({ ...prev, venue: e.target.value }))}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>
                        <div className="flex gap-2 justify-end">

                          <button
                            type="button"
                            onClick={() => setUpdateOpen(false)}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-3 sm:w-auto"
                          >
                            İptal
                          </button>
                          <button
                            type="submit"

                            className="mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                          >
                            Güncelle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
