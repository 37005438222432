
import img from '../../files/logo.png'
// import imgWhite from '../../files/logo-beyaz.png'
import {
    Dialog,
    DialogPanel,

    PopoverGroup,
} from '@headlessui/react'
import {

    Bars3Icon,

    XMarkIcon,

    ArrowRightEndOnRectangleIcon,
    ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline'
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext'
import { doSignOut } from '../../firebase/auth'


import toast from 'react-hot-toast'
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase/Firebase';


function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

        const [navbarBackground, setNavbarBackground] = useState(false);
        const handleSignOut = () => {
            doSignOut()
              .then(() => {
                toast.success('Çıkış yapıldı'); // Toast mesajı gösteriliyor
                navigate('/login');
              })
              .catch(error => {
                toast.error('Bir hata oluştu'); // Hata mesajı
              });
          };
        const navigate= useNavigate()
        const {userLoggedIn} = useAuth()

        const handleScroll = () => {
          if (window.scrollY > 56) {
            setNavbarBackground(true);
          } else {
            setNavbarBackground(false);
          }
        };
      
        useEffect(() => {
          window.addEventListener('scroll', handleScroll);
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);

        const [role, setRole] = useState(null); // Kullanıcının rolünü saklamak için
        const [loading, setLoading] = useState(true); // Yükleme durumunu takip etmek için
      
        useEffect(() => {
          const fetchUserRole = async () => {
            try {
              // Kullanıcının giriş yapıp yapmadığını kontrol et
              const user = auth.currentUser;
              if (user) {
                // Kullanıcının Firestore'daki rolünü getir
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  setRole(userData.role); // Kullanıcının rolünü state'e kaydet
                }
              }
            } catch (error) {
              console.error('Rol alınırken hata oluştu:', error);
            } finally {
              setLoading(false); // Yükleme durumu tamamlandı
            }
          };
      
          fetchUserRole();
        }, []);
      
        // Eğer kullanıcı admin değilse ya da yükleniyorsa butonu göstermeyiz
        if (loading) {
          return null; // Yüklenirken herhangi bir şey gösterme
        }
  return (
    <header className='relative w-full'>
        <nav aria-label="Global"     className={`fixed top-0 left-0 right-0 z-50 w-full bg-white shadow-md transition-colors duration-300`}
        >
        <div className="mx-auto max-w-7xl flex items-center justify-between p-4">
                <div className="flex lg:flex-1">
                    <Link to="/">
                        <span className="sr-only">Your Company</span>
                        <img alt="" src={`${navbarBackground? img : img}`} className="h-6 w-auto transition  hover:opacity-80 " />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${navbarBackground ? 'text-gray-900' : 'text-cyan-500'}`}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                    <Link to={'/takvim'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}>
                        Takvim
                    </Link>
                    <Link to={'/artist'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}>
                        Sanatçılar
                    </Link>
                    <Link to={'/sehirler'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}>
                        Şehirler
                    </Link>


                    {/* <Popover className="relative">
                        <PopoverButton className={`flex group items-center gap-x-1 text-sm font-semibold leading-6 hover:text-cyan-500  text-gray-900 transition outline-none ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}` }>
                            Etkinlikler
                            <ChevronDownIcon aria-hidden="true" className={`h-5 w-5 flex-none transition group-data-[open]:rotate-180 group-hover:text-cyan-500 ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}/>
                        </PopoverButton>

                        <PopoverPanel
                            transition
                            className="absolute -left-8 top-full z-10 mt-3 w-64 max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="p-2">
                                {products.map((item) => (

                                    <div
                                        key={item.name}
                                        className="group relative flex items-center gap-x-3 rounded-lg p-2 text-sm leading-6 cursor-pointer"
                                    >

                                        <div className="flex h-5 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white ">
                                            <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-cyan-500 transition" />
                                        </div>
                                        <div className="flex-auto">
                                            <a href={item.href} className="block font-semibold text-gray-900 group-hover:translate-x-1 group-hover:text-cyan-500  transition">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>

                                        </div>

                                    </div>
                                ))}
                            </div>

                        </PopoverPanel>
                    </Popover> */}
                    {/* <Link to={'/blog'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}>
                        Blog
                    </Link> */}
                    <Link to={'/sss'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`}>
                        SSS
                    </Link>
                    {role === 'admin' && (
      <Link
        to={"/admin"}
        className={`text-sm font-semibold leading-6  text-cyan-500 hover:text-cyan-500 transition`}
      >
        Admin
      </Link>)}
    
                </PopoverGroup>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {
                        userLoggedIn
                        ?
                        <><button className={`flex justify-between items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`} onClick={handleSignOut}>Çıkış Yap <ArrowRightStartOnRectangleIcon className='h-6 w-6 ml-1'/></button></>:<><Link className={`flex justify-between items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition ${navbarBackground ? 'text-gray-900' : 'text-gray-900'}`} to={'/login'}>Giriş Yap<span aria-hidden="true" className='ml-1 '><ArrowRightEndOnRectangleIcon className='h-6 w-6'/></span></Link> 
</>
                    }
                </div>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
    <div className="fixed inset-0 z-50" />
    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto shadow-md bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                    alt=""
                    src={img}
                    className="h-8 w-auto"
                />
            </a>
            <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
        </div>
        <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                    <Link to={"/takvim"}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Takvim
                    </Link>
                    <Link to={"/artist"}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Sanatçılar
                    </Link>
                    <Link to={'/sehirler'} 
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        Şehirler
                    </Link>
                    <Link to={"/sss"}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        SSS
                    </Link>
                </div>
                <div className="py-6">
                {
                    userLoggedIn
                    ? 
                    <button className="flex justify-start items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition" onClick={() => { handleSignOut(); setMobileMenuOpen(false); }}>
                        Çıkış Yap <ArrowRightStartOnRectangleIcon className='h-6 w-6 ml-1'/>
                    </button> 
                    : 
                    <Link className="flex justify-start items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition" to={'/login'} onClick={() => setMobileMenuOpen(false)}>
                        Giriş Yap <span aria-hidden="true" className='ml-1'><ArrowRightEndOnRectangleIcon className='h-6 w-6'/></span>
                    </Link>
                }
                </div>
            </div>
        </div>
    </DialogPanel>
</Dialog>

    </header>
  )
}

export default Navbar