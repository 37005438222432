import React, { useState, useCallback, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db, storage } from '../../firebase/Firebase';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDropzone } from 'react-dropzone';

const ref = collection(db, "etkinlikler");

function AddEvent({ setShowPopup }) {
    const categoriesRef = collection(db, 'categories');
    const [categories] = useCollectionData(categoriesRef);
    const [loading, setLoading] = useState(false);

    const [, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [fileUrlLink, setFileUrlLink] = useState('');
    const [dates, setDates] = useState([]); // Çoklu tarih
    const [selectedDate, setSelectedDate] = useState('');
    const [time, setTime] = useState('');
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [generalInfo, setGeneralInfo] = useState('');
    const [details, setDetails] = useState('');
    const [notes, setNotes] = useState('');
    const [city, setCity] = useState('');
    const [venue, setVenue] = useState('');
    const [price, setPrice] = useState('');
    const [purchaseUrl, setPurchaseUrl] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                setFile(file);
                try {
                    const fileRef = storageRef(storage, `images/${file.name}`);
                    await uploadBytes(fileRef, file);
                    const imageUrl = await getDownloadURL(fileRef);
                    setFileUrl(imageUrl);
                    toast.success('Fotoğraf başarıyla yüklendi!');
                } catch (error) {
                    toast.error('Fotoğraf yüklenemedi!');
                }
            }
        }
    });

    const handleCancel = () => {
        setShowPopup(false);
    };

    const [artists, setArtists] = useState([]);
    const [selectedArtists, setSelectedArtists] = useState([]); // Çoklu sanatçı

    useEffect(() => {
        const fetchArtists = async () => {
            const artistsRef = collection(db, "artists");
            const snapshot = await getDocs(artistsRef);
            const artistsData = snapshot.docs.map((doc) => doc.data());
            setArtists(artistsData);
        };

        fetchArtists();
    }, []);

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        setTime(e.target.value);
    };

    const handleAddDateTime = () => {
        if (selectedDate && time) {
            const combinedDateTime = `${selectedDate}T${time}`;
            setDates(prevDates => [...prevDates, combinedDateTime]);
            setSelectedDate('');
            setTime('');
        } else {
            toast.error('Tarih ve saat seçilmelidir!');
        }
    };

    const handleDateRemove = (dateToRemove) => {
        setDates(prevDates => prevDates.filter(date => date !== dateToRemove));
    };

    const handleArtistChange = (e) => {
        const selectedUid = e.target.value;
        const selectedArtist = artists.find(artist => artist.id === selectedUid);

        if (selectedArtist) {
            setSelectedArtists(prev => [...prev, selectedArtist]);
        }
    };

    const handleArtistRemove = (artistUid) => {
        setSelectedArtists(prev => prev.filter(artist => artist.id !== artistUid));
    };

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const timestamps = dates.map(date => Timestamp.fromDate(new Date(date)));

            await addDoc(ref, {
                imageUrl: fileUrl ? fileUrl : fileUrlLink,
                dates: timestamps,
                category: category,
                title: title,
                generalInfo: generalInfo,
                details: details,
                notes: notes,
                city: city,
                venue: venue,
                price: parseFloat(price),
                purchaseUrl: purchaseUrl,
                artists: selectedArtists.map(artist => ({
                    name: artist.name,
                    uid: artist.id,
                    imageUrl: artist.imageUrl
                }))
            });

            toast.success('Etkinlik başarıyla yüklendi.');
            setShowPopup(false);

        } catch (error) {
            toast.error('Etkinlik Yüklenemedi!');
        } finally {
            setLoading(false);
        }
    }, [fileUrl, dates, category, fileUrlLink, selectedArtists, title, generalInfo, details, notes, city, venue, price, purchaseUrl, setShowPopup]);

    if (loading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div className="relative flex items-center h-full justify-center">
            <div className="max-w-lg flex flex-col justify-center h-auto mx-auto bg-white rounded-lg p-6 shadow-md">
                <button
                    type="button"
                    onClick={handleCancel}
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <form className='flex flex-col justify-center h-auto' onSubmit={handleSubmit}>
                    <div>
                        <div className=" border-gray-900/10">
                            <div className="grid grid-cols-2 gap-x-4 gap-y-1 sm:grid-cols-12">
                                <div className="col-span-full">
                                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                        Fotoğraf
                                    </label>
                                    <div {...getRootProps({ className: 'mt-2 flex justify-center rounded-lg border border-dashed bg-white border-gray-900/25 px-6 py-10' })}>
                                        <input {...getInputProps()} />
                                        <div className="text-center">
                                            <p className="text-sm leading-6 text-gray-600">Dosyanızı buraya sürükleyip bırakın veya seçin.</p>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF maksimum 10MB</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-12">
                                    <label htmlFor="fileUrlLink" className="block text-sm font-medium leading-6 text-gray-900">
                                        Görsel zaten yüklü ise Url olarak giriniz
                                    </label>
                                    <input
                                        type="url"
                                        name="fileUrlLink"
                                        id="fileUrlLink"
                                        value={fileUrlLink}
                                        onChange={(e) => setFileUrlLink(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="addPurchaseUrl" className="block text-sm font-medium leading-6 text-gray-900">
                                        Satın Alma Url'si
                                    </label>
                                    <input
                                        type="url"
                                        name="addPurchaseUrl"
                                        id="addPurchaseUrl"
                                        value={purchaseUrl}
                                        onChange={(e) => setPurchaseUrl(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="addGeneralInfo" className="block text-sm font-medium leading-6 text-gray-900">
                                        Genel Bilgiler
                                    </label>
                                    <textarea
                                        id="addGeneralInfo"
                                        name="addGeneralInfo"
                                        value={generalInfo}
                                        onChange={(e) => setGeneralInfo(e.target.value)}
                                        rows="3"
                                        className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="addTitle" className="block text-sm font-medium leading-6 text-gray-900">
                                        Başlık
                                    </label>
                                    <input
                                        type="text"
                                        name="addTitle"
                                        id="addTitle"
                                        value={title}
                                        required
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="addCategory" className="block text-sm font-medium leading-6 text-gray-900">
                                        Kategori
                                    </label>
                                    <select
                                        id="addCategory"
                                        name="addCategory"
                                        value={category}
                                        required
                                        onChange={(e) => setCategory(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    >
                                        <option value="" disabled>Seçin...</option>
                                        {categories && categories.map((cat) => (
                                            <option key={cat.id} value={cat.name}>{cat.name}</option>
                                        ))}
                                    </select>
                                </div>



                                <div className="sm:col-span-6">
                                    <label htmlFor="addDetails" className="block text-sm font-medium leading-6 text-gray-900">
                                        Detaylar
                                    </label>
                                    <textarea
                                        id="addDetails"
                                        name="addDetails"
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                        rows="3"
                                        className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>



                                <div className="sm:col-span-3">
                                    <label htmlFor="addCity" className="block text-sm font-medium leading-6 text-gray-900">
                                        Şehir
                                    </label>
                                    <input
                                        type="text"
                                        id="addCity"
                                        name="addCity"
                                        value={city}
                                        required
                                        onChange={(e) => setCity(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="addVenue" className="block text-sm font-medium leading-6 text-gray-900">
                                        Mekan
                                    </label>
                                    <input
                                        type="text"
                                        id="addVenue"
                                        name="addVenue"
                                        required
                                        value={venue}
                                        onChange={(e) => setVenue(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="sm:col-span-6">
                                    <label htmlFor="addNotes" className="block text-sm font-medium leading-6 text-gray-900">
                                        Notlar
                                    </label>
                                    <textarea
                                        id="addNotes"
                                        name="addNotes"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                        rows="3"
                                        className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="addDate" className="block text-sm font-medium leading-6 text-gray-900">
                                        Etkinlik Tarih ve Saat
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            type="date"
                                            id="addDate"
                                            name="addDate"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                        />
                                        <input
                                            type="time"
                                            id="addTime"
                                            name="addTime"
                                            value={time}
                                            onChange={handleTimeChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleAddDateTime}
                                            className="rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold text-white hover:bg-green-600"
                                        >
                                            Ekle
                                        </button>
                                    </div>
                                    {dates.length > 0 && (
                                        <ul className="mt-2">
                                            {dates.map((date, index) => (
                                                <li key={index} className="flex items-center gap-2">
                                                    <span>{new Date(date).toLocaleString()}</span>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleDateRemove(date)}
                                                        className="text-red-600"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-5 w-5"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <path d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="addPrice" className="block text-sm font-medium leading-6 text-gray-900">
                                        Fiyat
                                    </label>
                                    <input
                                        type="number"
                                        id="addPrice"
                                        name="addPrice"
                                        required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="addArtists" className="block text-sm font-medium leading-6 text-gray-900">
                                        Sanatçılar
                                    </label>
                                    <select
                                        id="addArtists"
                                        name="addArtists"
                                        onChange={handleArtistChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                                    >
                                        <option value="" disabled>Sanatçı seçin...</option>
                                        {artists.map((artist) => (
                                            <option key={artist.id} value={artist.id}>{artist.name}</option>
                                        ))}
                                    </select>
                                    <ul className="mt-2">
                                        {selectedArtists.map((artist) => (
                                            <li key={artist.id} className="flex items-center gap-2">
                                                <span>{artist.name}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleArtistRemove(artist.id)}
                                                    className="text-red-600"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className='mb-3 mt-1' />
                        <div className=" flex gap-4 justify-end">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="text-gray-700 hover:text-gray-900"
                            >
                                İptal
                            </button>
                            <button
                                type="submit"
                                className="rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold text-white hover:bg-green-600"
                            >
                                Kaydet
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddEvent;
