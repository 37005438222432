import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import logo from '../../files/logo.png'
const PasswordReset = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const oobCode = query.get('oobCode');

  const [newPassword, setNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccessMessage('Şifreniz başarıyla sıfırlandı.');
    } catch (error) {
      setErrorMessage('Şifre sıfırlama sırasında bir hata oluştu.');
    }
  };

  return (
    <div className='w-full h-screen justify-center items-center flex flex-col bg-gray-200'>
        <img src={logo} alt="" className='h-8 object-cover'/>
        <div className="p-4 max-w-md mx-auto mt-5 shadow-md rounded-lg bg-white">
      <h2 className="text-2xl font-bold mb-4 text-center">Şifreyi Sıfırla</h2>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div>
          <label htmlFor="newPassword" className="block mb-1 text-gray-700">Yeni Şifre</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
            required
          />
        </div>
        <button
          type="submit"
          className="w-52 sm:w-96 px-4 py-1.5 bg-cyan-500 text-white rounded hover:bg-cyan-600"
        >
          Şifreyi Sıfırla
        </button>
      </form>
      {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
    </div>
    </div>
  );
};

export default PasswordReset;
