import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  // Çerezi kontrol et (eğer çerez daha önce kabul edildiyse, banner gösterilmez)
  useEffect(() => {
    const cookieAccepted = localStorage.getItem("cookieAccepted");
    if (cookieAccepted) {
      setShowBanner(false);
    }
  }, []);

  const acceptCookies = () => {
    // Kullanıcının kabul ettiğini kaydet (localStorage'da)
    localStorage.setItem("cookieAccepted", "true");
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="fixed bottom-0 z-50 left-0 right-0 mx-auto m-3 border-2 shadow-md border-white bg-cyan-500 text-white p-4 flex flex-col sm:flex-row justify-between items-center max-w-full sm:max-w-7xl rounded-full space-y-2 sm:space-y-0">
        <p className="text-center sm:text-left text-sm sm:text-base">
          Bu site çerezleri kullanır. Devam ederek çerez politikamızı kabul etmiş olursunuz.
        </p>
        <button
          onClick={acceptCookies}
          className="bg-white text-cyan-500 py-1 px-4 rounded-full text-sm sm:text-base"
        >
          Kabul Et
        </button>
      </div>
    )
  );
};

export default CookieBanner;
