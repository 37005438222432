import React from 'react';
import AddEvent from './AddEvent'; // AddEvent formunu import et

const Popup = ({ setShowPopup }) => {
  


  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center popup-overlay"// Arka plana tıklama olayını dinle
    >
      <div className="bg-white shadow-lg relative rounded-lg">
        <AddEvent setShowPopup={setShowPopup}/> {/* AddEvent formu buraya render edilecek */}
      </div>
    </div>
  );
};

export default Popup;
