import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import Modal from "./Modal"; // Ensure this path is correct
import AddArtist from "./AddArtist";

const AdminPanelArtists = () => {
  const [artists, setArtists] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi için state
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);
  
  // Onay penceresi için state'ler
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [artistToDelete, setArtistToDelete] = useState(null);
  const [artistToReset, setArtistToReset] = useState(null);

  const fetchArtists = async () => {
    try {
      setLoading(true);
      const artistsCollection = collection(db, "artists");
      const artistSnapshot = await getDocs(artistsCollection);
      const artistList = artistSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      artistList.sort((a, b) => (b.createdAt.seconds - a.createdAt.seconds));
      setArtists(artistList);
      setFilteredArtists(artistList); // İlk yüklemede filtrelenmiş sanatçılar da ayarlanır
    } catch (error) {
      console.error("Sanatçılar alınırken bir hata oluştu:", error);
  } finally {
      setLoading(false); // Yüklenmeyi bitir
    }
  };

  useEffect(() => {
    fetchArtists();
  }, []);

  const handleArtistAdded = async () => {
    await fetchArtists();
  };

  useEffect(() => {

    if (searchTerm === "") {
      setFilteredArtists(artists);
    } else {
      setFilteredArtists(
        artists.filter((artist) =>
          artist.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, artists]);

  const handleEdit = (artist) => {
    setSelectedArtist(artist);
    setName(artist.name);
    setImageUrl(artist.imageUrl);
    setAbout(artist.about);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleResetClickCount = async (id) => {
    try {
        setLoading(true);
      const artistRef = doc(db, "artists", id);
      await updateDoc(artistRef, { clickCount: 0 });
      setArtists(artists.map(artist =>
        artist.id === id ? { ...artist, clickCount: 0 } : artist
      ));
      setFilteredArtists(filteredArtists.map(artist =>
        artist.id === id ? { ...artist, clickCount: 0 } : artist
      ));
    } catch (error) {
      console.error("Tıklanma sayısı sıfırlanırken bir hata oluştu:", error);
    } finally{
        setLoading(false);
    }
  };

  const handleConfirmReset = (id) => {
    setArtistToReset(id);
    setIsConfirmOpen(true);
  };

  const handleConfirmResetClick = async () => {
    if (artistToReset) {
      await handleResetClickCount(artistToReset);
      setArtistToReset(null);
    }
    setIsConfirmOpen(false);
  };

  const handleCancelReset = () => {
    setArtistToReset(null);
    setIsConfirmOpen(false);
  };

  const handleConfirmDelete = (id) => {
    setArtistToDelete(id);
    setIsConfirmOpen(true);
  };

  const handleConfirmDeleteClick = async () => {
    if (artistToDelete) {
      try {
        setLoading(true);
        await deleteDoc(doc(db, "artists", artistToDelete));
        setArtists(artists.filter(artist => artist.id !== artistToDelete));
        setFilteredArtists(filteredArtists.filter(artist => artist.id !== artistToDelete));
        setArtistToDelete(null);
      } catch (error) {
        console.error("Sanatçı silinirken bir hata oluştu:", error);
      } finally{
        setLoading(false);
      }
    }
    setIsConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setArtistToDelete(null);
    setIsConfirmOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isEditing && selectedArtist) {
      try {
        setLoading(true);
        const artistRef = doc(db, "artists", selectedArtist.id);
        await updateDoc(artistRef, { name, imageUrl, about });
        setArtists(artists.map(artist =>
          artist.id === selectedArtist.id ? { ...artist, name, imageUrl, about } : artist
        ));
        setFilteredArtists(filteredArtists.map(artist =>
          artist.id === selectedArtist.id ? { ...artist, name, imageUrl, about } : artist
        ));
        setIsEditing(false);
        setSelectedArtist(null);
      } catch (error) {
        console.error("Sanatçı güncellenirken bir hata oluştu:", error);
      } finally{
        setLoading(false);
      }
    }
    
    setName("");
    setImageUrl("");
    setAbout("");
    setIsModalOpen(false);
  };

  return (
    <div>
        {loading ? <div>yükleniyor</div> : <div>
      <div className="flex flex-col mb-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Sanatçılar Yönetim Paneli</h1>
          <div><AddArtist onArtistAdded={handleArtistAdded}/></div>
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Sanatçı Ara..."
          className="mb-4 p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredArtists.map((artist) => (
          <div key={artist.id} className="border p-4 rounded-md">
            <h2 className="text-xl font-semibold">{artist.name}</h2>
            <img src={artist.imageUrl} alt={artist.name} className="w-full h-auto mt-2 mb-2" />
            <p>{artist.about}</p>
            <p>Tıklanma sayısı ({artist.clickCount})</p>
            <div className="mt-2">
              <button
                onClick={() => handleEdit(artist)}
                className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
              >
                Düzenle
              </button>
              <button
                onClick={() => handleConfirmDelete(artist.id)}
                className="bg-red-500 text-white py-1 px-2 rounded mr-2"
              >
                Sil
              </button>
              <button
                onClick={() => handleConfirmReset(artist.id)}
                className="bg-yellow-500 text-white py-1 px-2 rounded"
              >
                Sıfırla
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">{isEditing ? "Sanatçı Güncelle" : "Sanatçı Ekle"}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Sanatçı Adı</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700">Görsel URL'si</label>
            <input
              type="text"
              id="imageUrl"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700">Hakkında</label>
            <textarea
              id="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="submit"
              className="bg-blue-500 text-white py-1 px-2 rounded"
            >
              {isEditing ? "Güncelle" : "Ekle"}
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-500 text-white py-1 px-2 rounded"
            >
              Kapat
            </button>
          </div>
        </form>
      </Modal>

      {/* Onay penceresi */}
      {isConfirmOpen && (
        <Modal isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
          <h2 className="text-lg font-semibold mb-4">Onayla</h2>
          <p>
            {artistToDelete ? "Bu sanatçıyı silmek istediğinize emin misiniz?" : ""}
            {artistToReset ? "Tıklanma sayısını sıfırlamak istediğinize emin misiniz?" : ""}
          </p>
          <div className="flex justify-end gap-4 mt-4">
            <button
              onClick={artistToDelete ? handleConfirmDeleteClick : handleConfirmResetClick}
              className="bg-red-500 text-white py-1 px-2 rounded"
            >
              {artistToDelete ? "Evet, Sil" : "Evet, Sıfırla"}
            </button>
            <button
              onClick={artistToDelete ? handleCancelDelete : handleCancelReset}
              className="bg-gray-500 text-white py-1 px-2 rounded"
            >
              İptal
            </button>
          </div>
        </Modal>
      )}
    </div>}
    </div>
  );
};

export default AdminPanelArtists;
