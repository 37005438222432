
import React from 'react';
import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { db } from '../../firebase/Firebase';
import { collection, doc, deleteDoc, updateDoc, addDoc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const categoriesRef = collection(db, 'categories');

export default function AllCategories() {
  const [snapshot, loadingCategories] = useCollection(categoriesRef);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const deleteCategory = async () => {
    if (currentCategoryId) {
      setLoading(true);
      try {
        const categoryDoc = doc(db, 'categories', currentCategoryId);
        await deleteDoc(categoryDoc);
        setOpen(false);
        setCurrentCategoryId(null);
        toast.success('Kategori başarıyla kaldırıldı.')
      } catch (error) {
        console.error("Kategori silinirken hata oluştu: ", error);
        toast.success('Kategori silinirken hata oluştu.')
      } finally {
        setLoading(false);
      }
    }
  };

  if (loadingCategories) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loadingCategories}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }
  if (loading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }

  if (addLoading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={addLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }

  if (updateLoading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={updateLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }

  const updateCategory = async () => {
    if (currentCategoryId && currentCategory) {
      setUpdateLoading(true);
      try {
        const categoryDoc = doc(db, 'categories', currentCategoryId);
        await updateDoc(categoryDoc, { name: currentCategory.name });
        setUpdateOpen(false);
        setCurrentCategoryId(null);
        setCurrentCategory(null);
        toast.success('Kategori başarıyla güncellendi.')
      } catch (error) {
        console.error("Kategori güncellenirken hata oluştu: ", error);
        toast.success('Kategori güncellenirken hata oluştu.')
      } finally {
        setUpdateLoading(false);
      }
    }
  };

  const addCategory = async () => {
    if (newCategoryName) {
      setAddLoading(true);
      try {
        await addDoc(categoriesRef, { name: newCategoryName });
        setAddOpen(false);
        setNewCategoryName('');
        toast.success('Kategori başarıyla eklendi.')
      } catch (error) {
        console.error("Kategori eklenirken hata oluştu: ", error);
        toast.error('Kategori eklenirken hata oluştu.')
      } finally {
        setAddLoading(false);
      }
    }
  };

  if (loadingCategories) {
    return <div>Yükleniyor...</div>;
  }

  if (!snapshot || snapshot.empty) {
    return <div>Gösterilecek kategori yok.</div>;
  }

  return (
    <div className='w-full'>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Kategoriler</h2>
        <button
          onClick={() => setAddOpen(true)}
          className="inline-flex items-center justify-center rounded bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
        >
          Yeni Kategori Ekle
        </button>
      </div>

      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-3 gap-y-3 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {snapshot.docs.map((doc) => {
          const category = doc.data();
          const id = doc.id;

          return (
            <article key={id} className="flex flex-col items-start rounded-lg bg-gray-50 shadow-md">
              <div className='p-4 w-full flex justify-between items-center'>
                <h3 className="text-lg font-bold leading-6 truncate w-full text-gray-900">
                  {category.name}
                </h3>

                <div className='flex justify-end items-center'>
                <button
                  onClick={() => { setOpen(true); setCurrentCategoryId(id); }}
                  className=" mr-2 ml-2 text-sm rounded relative z-30 font-semibold bg-red-500 px-3 py-1 text-white hover:bg-red-600"
                >
                  Sil
                </button>
                <button
                  onClick={() => { setUpdateOpen(true); setCurrentCategoryId(id); setCurrentCategory(category); }}
                  className=" text-sm rounded relative z-30 font-semibold bg-blue-500 px-3 py-1 text-white hover:bg-blue-600"
                >
                  Düzenle
                </button>
                </div>
              </div>
            </article>
          );
        })}
      </div>

      {/* Onay Penceresi */}
      <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Kategoriyi Sil
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Bu kategoriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={deleteCategory}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                 Sil
                </button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  İptal
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Güncelleme Penceresi */}
      <Dialog open={updateOpen} onClose={() => setUpdateOpen(false)} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5">
                <div className="sm:flex sm:items-start">
                  <div className="text-left w-full mt-0">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                      Kategoriyi Güncelle
                    </DialogTitle>
                    <div className="mt-2">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateCategory();
                        }}
                        className="space-y-4"
                      >
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Kategori Adı
                          </label>
                          <input
                            id="name"
                            type="text"
                            value={currentCategory?.name || ''}
                            onChange={(e) => setCurrentCategory(prev => ({ ...prev, name: e.target.value }))}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          />
                        </div>

                        <div className="flex gap-3 justify-end">
                          
                          <button
                            type="button"
                            onClick={() => setUpdateOpen(false)}
                            className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            İptal
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
                          >
                            Güncelle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Ekleme Penceresi */}
      <Dialog open={addOpen} onClose={() => setAddOpen(false)} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5">
                <div className="sm:flex sm:items-start">
                  <div className="text-left w-full mt-0">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
                      Yeni Kategori Ekle
                    </DialogTitle>
                    <div className="mt-2">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          addCategory();
                        }}
                        className="space-y-4"
                      >
                        <div>
                          <label htmlFor="newName" className="block text-sm font-medium text-gray-700">
                            Yeni Kategori Adı
                          </label>
                          <input
                            id="newName"
                            type="text"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                          />
                        </div>

                        <div className="flex gap-3 justify-end">
                          
                          <button
                            type="button"
                            onClick={() => setAddOpen(false)}
                            className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            İptal
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
                          >
                            Ekle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
