import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/authContext';
import ReactGA from 'react-ga';


ReactGA.initialize('G-1L7MT2T4KL');
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router> 
  <AuthProvider>
    <App />
  </AuthProvider>
  </Router>
);

reportWebVitals();
