import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

export default function Artist() {
    useEffect(() => {
        AOS.init({
          duration: 1000, // animasyon süresi
        });
    }, []);
  return (
    <div className="relative overflow-hidden p-4 max-w-7xl mx-auto bg-white mt-24 mb-24">
      <div className="pb-40 pt-16 sm:pb-32 sm:pt-24 lg:pb-48 lg:pt-32">
        <div className="relative mx-auto max-w-7xl">
          <div className="sm:max-w-lg">
            <h1 data-aos="fade-up" className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Sanatçılarla Tanışın
            </h1>
            <p data-aos="fade-up" className="mt-4 text-lg text-gray-600 sm:text-xl">
              Yeni sanatçılar keşfedin ve onların eserlerini yakından inceleyin. Her bir sanatçının kendine özgü stilini ve yaratıcılığını keşfetmek için sanatçılar sayfamızı ziyaret edin.
            </p>
          </div>
          <div className="mt-10">
            <div
              aria-hidden="true"
              data-aos="fade-up"
              className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
            >
              <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                <div className="flex items-center space-x-6 lg:space-x-8">
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                      <img
                        alt="Artist 1"
                        src="https://www.sivasekspres.com/files/uploads/news/small/hadise-nin-yeni-yil-reklam-anlasmasi-rekor-kirdi-473325.webp"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt="Artist 2"
                        src="https://izgazetenet.teimg.com/crop/1280x720/izgazete-net/uploads/2024/08/semicenkaaa111.png"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt="Artist 3"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-03.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110">
  <img
    alt="Artist 4"
    src="https://lastfm.freetls.fastly.net/i/u/ar0/906f7f495861d8fa1cd3393447d2275d.jpg"
    className="h-full w-full object-cover object-center"
  />
</div>

                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt="Artist 5"
                        src="https://i.scdn.co/image/ab676161000051748627eb13fd9d1e67c521dedc"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                  <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt="Artist 6"
                        src="https://cdn.bubilet.com.tr/files/Sanatci/m-lisa-21092.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <div className="h-64 w-44 overflow-hidden rounded-lg">
                      <img
                        alt="Artist 7"
                        src="https://tr.web.img3.acsta.net/pictures/17/03/01/10/17/427597.jpg"
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link
              to="/artist"
              data-aos="fade-up"
              className="inline-block mt-8 rounded-full border border-transparent bg-cyan-500 px-8 py-3 text-center font-medium text-white hover:bg-cyan-600"
            >
              Sanatçıları Keşfet
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
