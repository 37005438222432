import React, { useCallback, useState } from 'react'

import { useAuth } from '../../contexts/authContext';
import { Link, Navigate } from 'react-router-dom';

import img from '../../files/logo.png'

import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/Firebase';



function ForgotPassword() {


  


    const {userLoggedIn} = useAuth()

    const [email, setEmail] = useState('');

    
    const onSubmit = useCallback((e) => {
        e.preventDefault();
       if(!email){
        return
       }
    
       sendPasswordResetEmail(auth,email).then(()=>{}).catch((e)=> {console.log(e)})
    },[email])

  
  return (
    <div className='h-screen w-full'>
        {userLoggedIn && (<Navigate to={'/'} replace={true}/>)}
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src={img}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
            Şifremi unuttum
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form action="#" onSubmit={onSubmit} method="POST" className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500">
              E-posta adresi
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                onClick={onSubmit}
                className="flex w-full justify-center rounded-md bg-cyan-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-600 outline-none"
              >
                PAROLAYI SIFIRLA
              </button>
            </div>
            
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
          Üye değil misiniz?{' '}
            
            <Link to={'/register'} className="font-semibold leading-6 text-cyan-500 hover:text-cyan-600">
            Hemen üye ol</Link>
            
          </p>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword