import React from 'react';

import './App.css'

import AppRoutes from './components/routes/AppRoutes'

import { Toaster } from 'react-hot-toast';
import ScrollToTopButton from './components/scrollbutton/ScrollToTopButton';
import Navbar from './components/navbar/Navbar';
import CookieBanner from './components/cerezpolitikası/CookieBanner';


export default function Example() {

 
  return (
    <>

      <div>
        <CookieBanner/>
        <AppRoutes/>
        <ScrollToTopButton/>
        <Toaster position='top'/>
        <Navbar/>
      </div>
    </>
  )
}
