import React, { useState, useMemo, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase/Firebase';
import { collection, getDocs } from 'firebase/firestore';
import { GrLocation } from 'react-icons/gr';
import { MdOutlineDateRange } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// Import DatePicker
import tr from 'date-fns/locale/tr'; 
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for DatePicker
import AOS from 'aos';
import 'aos/dist/aos.css';


const cities = [
  "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Aksaray", "Amasya", "Ankara", "Antalya",
  "Artvin", "Aydın", "Balıkesir", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur",
  "Bursa", "Çanakkale", "Çankırı", "Çorum", "Denizli", "Diyarbakır", "Düzce", "Edirne",
  "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane",
  "Hakkari", "Hatay", "Iğdır", "Isparta", "İstanbul", "İzmir", "Kahramanmaraş", "Karabük",
  "Karaman", "Kars", "Kastamonu", "Kayseri", "Kırıkkale", "Kırklareli", "Kırşehir",
  "Kilis", "Konya", "Kocaeli", "Kütahya", "Malatya", "Manisa", "Mardin", "Mersin",
  "Muğla", "Muş", "Nevşehir", "Niğde", "Ordu", "Osmaniye", "Rize", "Sakarya",
  "Samsun", "Şanlıurfa", "Şırnak", "Sinop", "Sivas", "Şile", "Tekirdağ", "Tokat",
  "Trabzon", "Tunceli", "Uşak", "Van", "Yalova", "Yozgat", "Zonguldak"
].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())); // Küçük harfe dönüştürerek sıralama

registerLocale('tr', tr);
setDefaultLocale('tr');

const etkinliklerRef = collection(db, 'etkinlikler');

const Event = ({ visibleEvents }) => {
  const [snapshot, loadingEtkinlikler] = useCollection(etkinliklerRef);
  const [sortOrder, setSortOrder] = useState('asc'); // Yeni state: sıralama düzeni
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [showFreeOnly, setShowFreeOnly] = useState(false); 
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // animasyon süresi
    });
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Kategorileri alacağınız koleksiyonu belirtin
        const categorySnapshot = await getDocs(collection(db, 'categories'));
        const categoryList = categorySnapshot.docs.map(doc => doc.data().name.toLowerCase()); // Kategori adlarını küçük harfe dönüştür
        setCategories(categoryList); // Kategori listesini state'e kaydedin
      } catch (error) {
        console.error("Kategoriler alınırken hata oluştu: ", error);
      }
    };

    fetchCategories();
  }, []);

  const navigate = useNavigate();

  const handleCityChange = e => setSelectedCity(e.target.value.toLowerCase());
  const handleCategoryChange = e => setSelectedCategory(e.target.value.toLowerCase());
  const handleDateRangeChange = dates => setSelectedDateRange(dates);

  const filteredEvents = useMemo(() => {
    return snapshot?.docs.filter(doc => {
      const etkinlikler = doc.data();
      const eventDates = etkinlikler.dates?.map(date => date.toDate()); // Tarihleri Date objesine dönüştür
      const selectedStartDate = selectedDateRange[0] ? new Date(selectedDateRange[0]) : null;
      const selectedEndDate = selectedDateRange[1] ? new Date(selectedDateRange[1]) : null;
  
      const matchesCity = selectedCity ? etkinlikler.city.toLowerCase() === selectedCity : true;
      const matchesCategory = selectedCategory ? etkinlikler.category.toLowerCase() === selectedCategory : true;
  
      const matchesDate = selectedStartDate && selectedEndDate
        ? eventDates?.some(date => date >= selectedStartDate && date <= selectedEndDate)
        : true;
  
      const matchesFreeOnly = showFreeOnly ? !etkinlikler.price || etkinlikler.price === 0 : true;
  
      return matchesCity && matchesCategory && matchesDate && matchesFreeOnly;
    });
  }, [snapshot, selectedCity, selectedCategory, selectedDateRange, showFreeOnly]);
  

  const sortedEvents = useMemo(() => {
    return filteredEvents?.sort((a, b) => {
      const priceA = a.data().price || 0;
      const priceB = b.data().price || 0;

      if (sortOrder === 'asc') {
        return priceA - priceB; // Artan sırada sıralama
      } else {
        return priceB - priceA; // Azalan sırada sıralama
      }
    });
  }, [filteredEvents, sortOrder]);

  if (loadingEtkinlikler) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingEtkinlikler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <div className="mx-auto max-w-7xl mt-8 p-4">
      {/* Filtreleme Butonu */}
      <div className="grid grid-cols-1 place-items-center items-center sm:grid-cols-2 lg:grid-cols-4 relative gap-4">
        {/* Şehir Seçimi */}
        <select data-aos="fade-up" value={selectedCity} onChange={handleCityChange} 
                className="block w-full ml-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:max-w-xs sm:text-sm sm:leading-6">
          <option value="">Şehir Seçin</option>
          {cities.map((city) => (
            <option value={city.toLowerCase()} key={city}>
              {city}
            </option>
          ))}
        </select>

        {/* Etkinlik Türü Seçimi */}
        <select
          value={selectedCategory}
          data-aos="fade-up"
          onChange={handleCategoryChange}
          className="block w-full mr-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="">Kategoriler</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>

        {/* Tarih Aralığı Seçimi */}
        <div data-aos="fade-up" className="relative grid z-30 w-full">
          <DatePicker
            selected={selectedDateRange[0]}
            onChange={handleDateRangeChange}
            startDate={selectedDateRange[0]}
            endDate={selectedDateRange[1]}
            dateFormat="dd MM yyyy"
            selectsRange
            isClearable
            placeholderText="Tarih Aralığını Seçin"
            className="block w-full ml-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:max-w-xs sm:text-sm sm:leading-6"
            locale="tr"
          />
        </div>

        {/* Sıralama Düzeni */}
        <select
          value={sortOrder}
          aria-label="Fiyat Sıralaması"
          data-aos="fade-up"
          onChange={(e) => setSortOrder(e.target.value)}
          className="block w-full rounded-md mr-auto border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="asc">Fiyat: Artan</option>
          <option value="desc">Fiyat: Azalan</option>
        </select>
      </div>
      <div data-aos="fade-up" className="flex lg:justify-start justify-center items-center mt-3">
        <input
          type="checkbox"
          id="checkboxInput" 
          checked={showFreeOnly}
          onChange={(e) => setShowFreeOnly(e.target.checked)}
          className="mr-2 h-5 w-5 text-cyan-500 focus:ring-cyan-500 border-gray-300 rounded"
        />
        <label  for="checkboxInput" className="text-gray-900 font-medium truncate select-none">Sadece Ücretsiz Etkinlikleri Göster</label>
      </div>
      {/* Etkinlik Kartları */}
      <div className="mx-auto grid max-w-2xl grid-cols-2 sm:grid-cols-3 sm:p-0 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:text-sm lg:grid-cols-6 lg:p-0 mt-10">
        {sortedEvents?.slice(0, visibleEvents).map((doc, index) => {
          const etkinlikler = doc.data();
          const id = doc.id;
          const firstDate = etkinlikler.dates && etkinlikler.dates.length > 0 ? etkinlikler.dates[0].toDate() : null;
          return (
            <article key={index} data-aos="fade-up" className="flex flex-col items-start rounded-xl bg-gray-50 shadow-md relative">
              {etkinlikler.imageUrl ? (
                <div className=' rounded-t-xl h-40 w-full overflow-hidden hover:cursor-pointer' onClick={() => navigate(`/event/${id}`)}>
                  <img className='w-full h-full hover:scale-105 transition  object-top object-cover' src={etkinlikler.imageUrl} alt=""/>
                </div>
              ) : (
                <div>
                  {etkinlikler.purchaseUrl ? <div className=' rounded-t-xl w-full overflow-hidden'>
                  <img className='w-full h-full object-cover' src={etkinlikler.purchaseUrl} alt="" />
                </div> : <div className=' rounded-t-xl w-full overflow-hidden'>
                  <img className='w-full h-full object-cover' src={etkinlikler.imageUrl} alt="" />
                </div>}
                </div>
              )}
              <div className='p-3 w-full h-[134px] relative overflow-hidden'>
                <div className="flex items-center gap-x-4 text-sm">
                {firstDate && (
                <time dateTime={firstDate.toLocaleDateString()} className="text-gray-500 gap-x-4 flex flex-col items-center justify-center gap-1">
                    <div className='flex items-center gap-4'>
                        <div className='flex items-center gap-1'>
                            <MdOutlineDateRange />
                            <p>{firstDate.toLocaleDateString()}</p>
                        </div>
                    </div>
                </time>
            )}
                 
                </div>
                <span className="mt-1 text-sm font-bold leading-6 line-clamp-1 text-gray-900 group-hover:text-gray-600">
                  <span className="absolute inset-0" />
                  {etkinlikler.title}
                </span>
                

                {/* Konum ve Mekan Bilgileri */}
                <div className='text-sm text-gray-500 justify-start items-center'>
                  <div className='flex justify-start items-center gap-1  mt-1'>
                  <GrLocation /><p className='flex justify-start items-center gap-1'>{etkinlikler.city}</p>
                  </div>
                 
                </div>
                {/* İncele Butonu */}
                <button
                  onClick={() => navigate(`/event/${id}`)}
                  className='bg-transparent text-cyan-500 border border-cyan-500 px-4 py-1 rounded font-medium text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors duration-300 absolute bottom-3 left-3'
                >
                  İncele
                </button>
                <p className="absolute right-3 px-1 py-1 bottom-3 text-sm font-bold text-cyan-500">{etkinlikler.price ? `${etkinlikler.price} TL` : 'Ücretsiz'}</p>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default Event;
