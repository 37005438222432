import React, { useState } from 'react';
import faqData from './FaqData';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
 
  return (
    <div className="max-w-7xl mx-auto px-4 py-6 mb-6">
      <h1 className="text-3xl font-bold text-center mb-6">Sıkça Sorulan Sorular (SSS)</h1>
      <div className="space-y-4">
        {faqData.map((item, index) => (
          <div key={index} className="border rounded-md overflow-hidden">
            <button
              className="w-full p-4 text-left bg-gray-100 focus:outline-none"
              onClick={() => toggleOpen(index)}
            >
              <h2 className="text-xl font-semibold">{item.question}</h2>
            </button>
            <div
              className={`transition-max-height duration-[1500ms] overflow-hidden ${
                openIndex === index ? 'max-h-screen' : 'max-h-0'
              }`}
            >
              <div className="p-4 bg-white border-t">
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;