import React from 'react'
import {
    PopoverGroup,
} from '@headlessui/react'
import {
    ArrowRightEndOnRectangleIcon,
    ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline'

import { useAuth } from '../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';
import { doSignOut } from '../../firebase/auth'
import toast from 'react-hot-toast';

function AdminPanel() {
    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    const handleSignOut = () => {
        doSignOut()
            .then(() => {
                toast.success('Çıkış yapıldı'); // Toast mesajı gösteriliyor
                navigate('/login');
            })
            .catch(error => {
                toast.error('Bir hata oluştu'); // Hata mesajı
            });
    };
    return (
        <div className="top-0 bg-gray-200 h-14 flex items-center w-full justify-between px-7">
            <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                <Link to={'/takvim'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `}>
                    takvim
                </Link>
                <Link to={'/artist'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `}>
                    Sanatçılar
                </Link>
                <Link to={'/blog'} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `}>
                    Blog
                </Link>
                <Link to={"/sss"} className={`text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `}>
                    SSS
                </Link>
            </PopoverGroup>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                {
                    userLoggedIn
                        ?
                        <><button className={`flex justify-between items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `} onClick={handleSignOut}>Çıkış Yap <ArrowRightStartOnRectangleIcon className='h-6 w-6 ml-1' /></button></> : <><Link className={`flex justify-between items-center text-sm font-semibold leading-6 text-gray-900 hover:text-cyan-500 transition `} to={'/login'}>Giriş Yap<span aria-hidden="true" className='ml-1 '><ArrowRightEndOnRectangleIcon className='h-6 w-6' /></span></Link>
                        </>
                }
            </div>
            
        </div>
    )
}

export default AdminPanel
