import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// 81 ilin numaraları ve isimleri
const iller = [
  { numara: 1, isim: 'Adana' },
  { numara: 2, isim: 'Adıyaman' },
  { numara: 3, isim: 'Afyonkarahisar' },
  { numara: 4, isim: 'Ağrı' },
  { numara: 5, isim: 'Amasya' },
  { numara: 6, isim: 'Ankara' },
  { numara: 7, isim: 'Antalya' },
  { numara: 8, isim: 'Artvin' },
  { numara: 9, isim: 'Aydın' },
  { numara: 10, isim: 'Balıkesir' },
  { numara: 11, isim: 'Bilecik' },
  { numara: 12, isim: 'Bingöl' },
  { numara: 13, isim: 'Bitlis' },
  { numara: 14, isim: 'Bolu' },
  { numara: 15, isim: 'Burdur' },
  { numara: 16, isim: 'Bursa' },
  { numara: 17, isim: 'Çanakkale' },
  { numara: 18, isim: 'Çankırı' },
  { numara: 19, isim: 'Çorum' },
  { numara: 20, isim: 'Denizli' },
  { numara: 21, isim: 'Diyarbakır' },
  { numara: 22, isim: 'Edirne' },
  { numara: 23, isim: 'Elazığ' },
  { numara: 24, isim: 'Erzincan' },
  { numara: 25, isim: 'Erzurum' },
  { numara: 26, isim: 'Eskişehir' },
  { numara: 27, isim: 'Gaziantep' },
  { numara: 28, isim: 'Giresun' },
  { numara: 29, isim: 'Gümüşhane' },
  { numara: 30, isim: 'Hakkari' },
  { numara: 31, isim: 'Hatay' },
  { numara: 32, isim: 'Isparta' },
  { numara: 33, isim: 'Mersin' },
  { numara: 34, isim: 'İstanbul' },
  { numara: 35, isim: 'İzmir' },
  { numara: 36, isim: 'Kars' },
  { numara: 37, isim: 'Kastamonu' },
  { numara: 38, isim: 'Kayseri' },
  { numara: 39, isim: 'Kırklareli' },
  { numara: 40, isim: 'Kırşehir' },
  { numara: 41, isim: 'Kocaeli' },
  { numara: 42, isim: 'Konya' },
  { numara: 43, isim: 'Kütahya' },
  { numara: 44, isim: 'Malatya' },
  { numara: 45, isim: 'Manisa' },
  { numara: 46, isim: 'Kahramanmaraş' },
  { numara: 47, isim: 'Mardin' },
  { numara: 48, isim: 'Muğla' },
  { numara: 49, isim: 'Muş' },
  { numara: 50, isim: 'Nevşehir' },
  { numara: 51, isim: 'Niğde' },
  { numara: 52, isim: 'Ordu' },
  { numara: 53, isim: 'Rize' },
  { numara: 54, isim: 'Sakarya' },
  { numara: 55, isim: 'Samsun' },
  { numara: 56, isim: 'Siirt' },
  { numara: 57, isim: 'Sinop' },
  { numara: 58, isim: 'Sivas' },
  { numara: 59, isim: 'Tekirdağ' },
  { numara: 60, isim: 'Tokat' },
  { numara: 61, isim: 'Trabzon' },
  { numara: 62, isim: 'Tunceli' },
  { numara: 63, isim: 'Şanlıurfa' },
  { numara: 64, isim: 'Uşak' },
  { numara: 65, isim: 'Van' },
  { numara: 66, isim: 'Yozgat' },
  { numara: 67, isim: 'Zonguldak' },
  { numara: 68, isim: 'Aksaray' },
  { numara: 69, isim: 'Bayburt' },
  { numara: 70, isim: 'Karaman' },
  { numara: 71, isim: 'Kırıkkale' },
  { numara: 72, isim: 'Batman' },
  { numara: 73, isim: 'Şırnak' },
  { numara: 74, isim: 'Bartın' },
  { numara: 75, isim: 'Ardahan' },
  { numara: 76, isim: 'Iğdır' },
  { numara: 77, isim: 'Yalova' },
  { numara: 78, isim: 'Karabük' },
  { numara: 79, isim: 'Kilis' },
  { numara: 80, isim: 'Osmaniye' },
  { numara: 81, isim: 'Düzce' },
];

const SehirSecim = () => {
  const navigate = useNavigate();
  const [arama, setArama] = useState('');

  // Şehir ismine veya numarasına göre arama yapıp eşleşen şehirleri filtreleme
  const filtrelenmisIller = iller.filter(
    (il) =>
      il.isim.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(arama.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) ||
      il.numara.toString().includes(arama)
  );

  const handleSehirSecim = (sehir) => {
    navigate(`/sehirler/${sehir}`); // Seçilen şehre göre yönlendirme yapıyoruz
  };

  return (
    <div className="min-h-screen gap-2 bg-cyan-500 flex flex-col p-3 pt-6 pb-6">
  <div className='w-full max-w-7xl mx-auto'>
    <div className='gap-4 flex flex-col pt-14'>
      <span className='text-xl font-bold text-white text-center lg:text-left'>Şehrindeki Etkinlikleri Keşfet</span>
      <input
        type="text"
        placeholder="Şehir Ara..."
        value={arama}
        onChange={(e) => setArama(e.target.value)}
        className="block w-full rounded-full text-gray-900 ring-0 shadow-md focus:ring-0 border-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />   
      <div className="grid grid-cols-4 gap-2 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-9">
        {filtrelenmisIller.length > 0 ? (
          filtrelenmisIller.map((il) => (
            <button
              key={il.numara}
              onClick={() => handleSehirSecim(il.isim)}
              className="flex flex-col justify-center items-center bg-white p-4 overflow-hidden shadow-md shadow-cyan-800 rounded-3xl transition ring-4 ring-inset ring-gray-200 hover:ring-gray-300"
            >
              <span className="text-2xl text-gray-800 font-bold">{il.numara}</span>
              <span className="text-xs lg:text-sm mt-1 text-gray-600 truncate">{il.isim}</span>
            </button>
          ))
        ) : (
          <p className="text-white col-span-full text-center">Aradığınız şehir bulunamadı.</p>
        )}
      </div>
    </div>
  </div>
</div>

  );
};

export default SehirSecim;
