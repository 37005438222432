import React from 'react'
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'

import { MdEventNote } from "react-icons/md";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import logo from '../../files/logo.png'
import { Link } from 'react-router-dom';
import { FaUsers } from "react-icons/fa";
import { CiCircleList } from "react-icons/ci";
import { IoMdMicrophone } from "react-icons/io";


function AdminPanel() {
    return (
        <div className="flex overflow-hidden overflow-y-auto">
            {/* Sidebar */}
            <aside className="w-64 bg-gray-50 h-screen p-6">
                <div className="flex items-center justify-between">
                    <Link to={'/'} className="-m-1.5 p-1.5">
                        <span className="sr-only  ">Your Company</span>
                        <img
                            alt=""
                            src={logo}
                            className="h-8 w-auto"
                        />
                    </Link>
                </div>

                <nav className="mt-6 space-y-2">
                    <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500">
                            Etkinlikler
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2">

                            <Link to={'allevents'}
                                className="flex items-center justify-start gap-2 rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500"
                            ><MdEventNote className='h-5 w-5'/>
Tüm etkinlikler
                            </Link>
                            
                        </DisclosurePanel>
                    </Disclosure>
                    <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500">
                            Üyeler
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">
                            <Link to={'users'}
                                className="flex items-center justify-start gap-2 rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500"
                            ><FaUsers className='h-5 w-5'/>Tüm üyeler
                            </Link>
                           
                          

                        </DisclosurePanel>
                    </Disclosure>
                    <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500">
                            Kategoriler
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">

                            <Link to={'all-categories'}
                                className="flex items-center justify-start gap-2 rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500"
                            ><CiCircleList className='h-5 w-5' />Tüm kategoriler
                            </Link>
                            

                        </DisclosurePanel>
                    </Disclosure>
                    <Disclosure as="div" className="-mx-3">
                        <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500">
                            Sanatçılar
                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-none group-data-[open]:rotate-180" />
                        </DisclosureButton>
                        <DisclosurePanel className="mt-2 space-y-2">

                            <Link to={'allartists'}
                                className="flex items-center justify-start gap-2 rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-cyan-500"
                            ><IoMdMicrophone  className='h-5 w-5' />Tüm Sanatçılar
                            </Link>
                            

                        </DisclosurePanel>
                    </Disclosure>
                </nav>    
            </aside>

        </div>
    )
}

export default AdminPanel
