// src/pages/AddArtist.js
import React, { useState, useEffect } from "react";
import { collection, addDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase/Firebase";
import Modal from "./Modal"; // Ensure this path is correct
import { serverTimestamp } from "firebase/firestore";

const AddArtist = ({ onArtistAdded }) => {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [about, setAbout] = useState(""); // Yeni state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsAdmin(userData.role === "admin");
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          setError("Kullanıcı rolü alınırken bir hata oluştu.");
        }
      }
    };

    checkAdmin();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAdmin) {
      setError("Bu işlemi yapma yetkiniz yok.");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "artists"), {// Sanatçının UID'si
        name,
        imageUrl,
        about, // Yeni alan
        createdAt: serverTimestamp(), // Oluşturulma zaman damgası
      });
      const docId = docRef.id;
      await setDoc(docRef, { id: docId }, { merge: true });
      setSuccess(true);
      setName("");
      setImageUrl("");
      setAbout(""); // Yeni alan sıfırlama
      setIsModalOpen(false); // Close the modal
      if (onArtistAdded) onArtistAdded();
    } catch (error) {
      setError("Sanatçı eklenirken bir hata oluştu.");
    }
  };

  return (
    <div>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">Sanatçı başarıyla eklendi!</p>}
      <button
        onClick={() => setIsModalOpen(true)}
        className="inline-flex items-center justify-center rounded bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600"
      >
        Sanatçı Ekle
      </button>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold mb-4">Sanatçı Ekle</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Sanatçı Adı</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700">Görsel URL'si</label>
            <input
              type="text"
              id="imageUrl"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700">Hakkında</label>
            <textarea
              id="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              rows="4"
              required
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              İptal
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Ekle
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddArtist;
