// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider ,createUserWithEmailAndPassword,signInWithEmailAndPassword } from "firebase/auth";
import {getFirestore,collection,addDoc, doc, updateDoc ,getDocs,arrayUnion} from "firebase/firestore"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvD_pMNKp9Laap5VC1-tSxf6-P5DqlT_U",
  authDomain: "turkiyetkinlikleri-87965.firebaseapp.com",
  projectId: "turkiyetkinlikleri-87965",
  storageBucket: "turkiyetkinlikleri-87965.appspot.com",
  messagingSenderId: "518827060828",
  appId: "1:518827060828:web:efd65582d8cd478283c099",
  measurementId: "G-ZSW6DVJXB6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider()
const db=getFirestore(app)
const storage = getStorage(app);

export  {app , auth , db, googleProvider , collection, addDoc, arrayUnion,createUserWithEmailAndPassword, signInWithEmailAndPassword, doc, updateDoc , getDocs,storage};
