import React, { useState } from 'react';
import { collection, query, where, getDocs, writeBatch, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';
import toast from 'react-hot-toast';

const ArchiveEventsButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    setIsModalOpen(false); // Modalı kapat

    try {
      // Günümüz tarihini al
      const today = Timestamp.now();

      // Geçmiş etkinlikleri sorgula
      const pastEventsQuery = query(collection(db, 'etkinlikler'), where('date', '<', today));
      const pastEventsSnapshot = await getDocs(pastEventsQuery);

      if (!pastEventsSnapshot.empty) {
        // Batch işlemi başlat
        const batch = writeBatch(db);

        pastEventsSnapshot.forEach((docSnapshot) => {
          const eventData = docSnapshot.data();
          
          // Arşiv koleksiyonuna ekle
          const archiveRef = doc(db, 'archivedEtkinlikler', docSnapshot.id);
          batch.set(archiveRef, eventData);

          // Etkinliği etkinlikler koleksiyonundan sil
          batch.delete(docSnapshot.ref);
        });

        // Batch işlemini gerçekleştir
        await batch.commit();
        toast.success('Etkinlikler başarıyla arşivlendi.');
      } else {
        toast.error('Arşivlenecek geçmiş etkinlik bulunamadı.');
      }
    } catch (error) {
      toast.error('Etkinlikleri arşivlerken bir hata oluştu:', error);
    }
  };

  // Modal dışına tıklama olayını işleme
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleCloseModal();
    }
  };

  return (
    <div>
      <button
        onClick={handleOpenModal}
        className="inline-flex items-center justify-center rounded bg-orange-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-600"
      >
        Eski Etkinlikleri Arşivle
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleOutsideClick}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg w-96" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-lg font-semibold mb-4">Emin misiniz?</h2>
            <p className="text-sm text-gray-500 mb-4">Bu işlem geçmiş etkinlikleri arşivleyecektir. Devam etmek istiyor musunuz?</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={handleCloseModal}
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-3 sm:w-auto"
              >
                İptal
              </button>
              <button
                onClick={handleConfirm}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:w-auto"
              >
                Arşivle
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArchiveEventsButton;
