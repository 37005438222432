import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';// Firebase yapılandırmanızın doğru yola sahip olduğundan emin olun
import Header from '../header/Header';
import Event from '../events/Event';
// import Advertisement from '../advertisement/Advertisement'
import { db } from '../../firebase/Firebase';
import Footer from '../footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Artist from '../artist/Artist';
function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animasyon süresi
    });
  }, []);

  const [visibleEvents, setVisibleEvents] = useState(12); // Başlangıçta 4 etkinlik göster
  const [totalEvents, setTotalEvents] = useState(0); // Toplam etkinlik sayısını tutmak için state
  const initialCount = 12;
  const increment = 12;
  // Firestore'dan etkinlik sayısını al
  useEffect(() => {
    const fetchTotalEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'etkinlikler'));
        setTotalEvents(querySnapshot.size); // Toplam belge sayısını al
      } catch (error) {
        console.error('Etkinlikler alınırken hata oluştu:', error);
      }
    };

    fetchTotalEvents();
  }, []);

  const handleShowMore = () => {
    setVisibleEvents((prev) => prev + increment); // Her tıklamada 8 etkinlik daha göster
  };

  const handleShowLess = () => {
    setVisibleEvents((prev) => (prev > initialCount ? prev - increment : initialCount)); // Her tıklamada 8 etkinlik daha az göster
  };

  return (
    <div>  
      <Header />
      <div className='max-w-7xl mx-auto mt-12 mb-24'>
        <h1 data-aos="fade-up" className='text-4xl font-bold text-center text-cyan-500'>Tüm Etkinlikler</h1>
        <Event visibleEvents={visibleEvents} />
        
        <div className="text-center mt-8 flex justify-center gap-4">
          {visibleEvents > initialCount && (
            <button
              onClick={handleShowLess}
              data-aos="fade-up" className="rounded-md border border-cyan-500 w-52 bg-transparent px-4 py-2 text-sm font-semibold text-cyan-500 shadow-sm hover:bg-cyan-500 hover:text-white"
            >
              Daha Az Göster
            </button>
          )}
          {visibleEvents < totalEvents && (
            <button
              onClick={handleShowMore}
              data-aos="fade-up"
              className="rounded-md bg-cyan-500 px-4 py-2 w-52 text-sm font-semibold text-white shadow-sm hover:bg-cyan-600"
            >
              Daha Fazla Göster
            </button>
          )}
        </div>
      </div>
      
      {/* <div data-aos="fade-up" className='w-full bg-gray-200 py-10'>
      <Advertisement className="max-w-7xl"/>
      </div> */}
      <Artist/>
      <Footer/>
    </div>
  );
}

export default Home;
