import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, db } from '../../firebase/Firebase';
import { getDocs } from 'firebase/firestore';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Şehirlerin listesini oluşturun (örnek olarak bazı şehirler eklenmiştir, tamamını ekleyin)
const cities = [
  "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Aksaray", "Amasya", "Ankara", "Antalya",
  "Artvin", "Aydın", "Balıkesir", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur",
  "Bursa", "çanakkale", "çankırı", "çorum", "Denizli", "Diyarbakır", "Düzce", "Edirne",
  "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane",
  "Hakkari", "Hatal", "Iğdır", "Isparta", "istanbul", "izmir", "Kahramanmaraş", "Karabük",
  "Karaman", "Kars", "Kastamonu", "Kayseri", "Kırıkkale", "Kırklareli", "Kırşehir",
  "Kilis", "Konya", "Kocaeli", "Kütahya", "Malatya", "Manisa", "Mardin", "Mersin",
  "Muğla", "Muş", "Nevşehir", "Niğde", "Ordu", "Osmaniye", "Rize", "Sakarya",
  "Samsun", "şanlıurfa", "şırnak", "Sinop", "Sivas", "şile", "Tekirdağ", "Tokat",
  "Trabzon", "Tunceli", "Uşak", "Van", "Yalova", "Yozgat", "Zonguldak"
].sort();  // Şehirleri alfabetik sırayla sıralayın

const PriceRangeSlider = ({ min, max, value, onChange }) => (
  <div className="lg:w-52 w-full mb-4">
    <label for="rangeInput" className="block text-sm text-center font-bold leading-6 text-gray-500 mb-1 sr-only" htmlFor="price-range">
      Fiyat
    </label>
    <div className='h-10'>
    <input
      type="range"
      min={min}
      max={max}
      step={10}
      value={value}
      onChange={onChange}
      id="rangeInput"
      aria-labelledby="rangeInputLabel"
      style={{
        background: `linear-gradient(to right, rgb(6 182 212) ${((value - min) / (max - min)) * 100}%, #e5e7eb ${((value - min) / (max - min)) * 100}%)`
      }}
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-slide custom-range"
      />
    <div className="grid grid-cols-2 text-xs text-gray-500">
    
      <span className='mr-auto'>{value ? `0 - ${value} TL` : 'Ücretsiz'}</span>
      <span className='ml-auto'>{max} TL</span>
    </div>
    </div>
  </div>
);

const FilterPage = () => {
  const [filters, setFilters] = useState({
    category: '',
    city: '',
    price: '', // Başlangıç değeri
    date: ''
  });
  const [searchCity, setSearchCity] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [categories, setCategories] = useState([]); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories')); // 'categories' koleksiyonunu değiştirin
        const categoriesData = querySnapshot.docs.map(doc => doc.data().name); // Kategorilerin adlarını al
        setCategories(categoriesData);
      } catch (error) {
        console.error('Kategoriler alınamadı: ', error);
      }
    };

    fetchCategories();
  }, []);

  // Dropdown menüsünü kapatmak için dış tıklamaları dinleyin
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handlePriceChange = (e) => {
    const value = e.target.value === "0" ? "" : e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      price: value,
    }));
  };
  useEffect(() => {
    AOS.init({
      duration: 1000, // animasyon süresi
    });
  }, []);
  const handleCitySearch = (e) => {
    setSearchCity(e.target.value);
  };

  const handleCitySelect = (city) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: city.toLowerCase()
    }));
    setSearchCity(city); // Seçilen şehri inputa yansıt
    setDropdownOpen(false);
  };
  
  const handleFilter = () => {
    const queryParams = new URLSearchParams(filters).toString();
    navigate(`/filtered-events?${queryParams}`);
  };

  const filteredCities = cities.filter(city =>
    city.toLowerCase().includes(searchCity.toLowerCase())
  );

  return (
    <div className="flex justify-center sm:w-2/3 lg:w-auto items-center flex-col text-center lg:flex-row lg:text-left gap-4 bg-white z-30 p-8 py-6 rounded-xl relative">
      
      <div className="mb-4 lg:w-auto sm:w-full">
        <form>
        <label  className="block text-center text-sm font-bold leading-6 mb-1 text-gray-500" for="category" htmlFor="category">
          Kategori
        </label>
        <select
        id="category"
          name="category"
          value={filters.category}
          onChange={handleInputChange}
          className="block sm:w-full w-52 lg:w-52 rounded-full border-0 z-50 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        >
          <option value="">Kategori Seçin</option>
          {categories.map((category, index) => (
            <option key={index} value={category.toLowerCase()}>{category}</option>
          ))}
        </select>
        </form>
      </div>

      <div className="relative mb-4 lg:w-auto sm:w-full">
        <form>
        <label className="block text-sm font-bold text-center leading-6 mb-1 text-gray-500" htmlFor="city">
          Şehir
        </label>
        <input
          type="text"
          
          placeholder="Şehir Ara"
          value={searchCity}
          onChange={handleCitySearch}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="block sm:w-full w-52 lg:w-52 rounded-full border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        />
        {dropdownOpen && (
          <div className="absolute border border-gray-300 z-50 bg-white mt-1 w-full max-h-60 overflow-y-auto rounded-md shadow-lg" ref={dropdownRef}>
            {filteredCities.length > 0 ? (
              filteredCities.map((city) => (
                <div
                  key={city}
                  className="cursor-pointer border-b-[1px] lg:border-none p-2 hover:bg-gray-200"
                  onClick={() => handleCitySelect(city)}
                >
                  {city}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">Şehir bulunamadı.</div>
            )}
          </div>
        )}
        </form>
      </div>

<div className="mb-4 lg:w-52 w-full">
<label for="date"  className="block text-sm text-center font-bold leading-6 mb-1 text-gray-500" htmlFor="date">
          Fiyat
        </label>
      <PriceRangeSlider
        min={''}
        max={5000}
        value={filters.price === "" ? 0 : filters.price}
        onChange={handlePriceChange}
      />
</div>
<div className="mb-4 lg:w-52 w-full">
        <label for="date"  className="block text-sm text-center font-bold leading-6 mb-1 text-gray-500" htmlFor="date">
          Tarih
        </label>
        <input
          type="date"
          name="date"
          id="date"
          value={filters.date}
          onChange={handleInputChange}
          aria-labelledby="price-control"
          placeholder="Tarih seçin"
          className="block w-full lg:w-52 rounded-full border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        />
      </div>
      <button
        onClick={handleFilter}
        
        className='bg-cyan-500 z-40 absolute -bottom-5 hover:cursor-pointer hover:bg-cyan-600 transition text-white font-bold p-2 px-4 rounded-full'
      >
        ETKİNLİK ARA
      </button>
    </div>
  );
};

export default FilterPage;