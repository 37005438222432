'use client'
import { IoIosArrowRoundDown } from "react-icons/io";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaTwitter,
} from "react-icons/fa6";

import video from "../../files/header-bg.mp4"

import React, { useEffect } from 'react';
import FilterPage from "../FilterPage/FilterPage";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Header() {

    useEffect(() => {
        AOS.init({
            duration: 1000, // animasyon süresi
        });

    }, []);
    return (
        <header className='relative'>
            <div className="absolute w-full h-screen overflow-hidden">
                <video
                    className="absolute top-0 left-0 w-full h-full object-cover -z-10"
                    autoPlay
                    loop
                    muted
                    playsInline
                >
                    <source src={video} type="video/mp4" />
                </video>
                <div className="absolute inset-0 bg-black opacity-30"></div>
            </div>

            <div className='flex flex-col justify-center items-center h-screen'>
                <span className='text-white z-30 sm:text-3xl text-xl font-bold p-3 flex gap-3 justify-center items-center'>
                    ŞİMDİ ETKİNLİK ARA
                    {/* <div className="flex gap-3">
                    <div className="flex">
                    <div data-aos="fade-down" data-aos-delay="0">Ş</div>
                    <div data-aos="fade-down" data-aos-delay="50">İ</div>
                    <div data-aos="fade-down" data-aos-delay="100">M</div>
                    <div data-aos="fade-down" data-aos-delay="150">D</div>
                    <div data-aos="fade-down" data-aos-delay="200">İ</div>
                    </div>
                    <div className="flex">
                    <div data-aos="fade-down" data-aos-delay="250">E</div>
                    <div data-aos="fade-down" data-aos-delay="300">T</div>
                    <div data-aos="fade-down" data-aos-delay="350">K</div>
                    <div data-aos="fade-down" data-aos-delay="400">İ</div>
                    <div data-aos="fade-down" data-aos-delay="450">N</div>
                    <div data-aos="fade-down" data-aos-delay="500">L</div>
                    <div data-aos="fade-down" data-aos-delay="550">İ</div>
                    <div data-aos="fade-down" data-aos-delay="600">K</div>
                    </div>
                    <div className="flex">
                    <div data-aos="fade-down" data-aos-delay="650">A</div>
                    <div data-aos="fade-down" data-aos-delay="700">R</div>
                    <div data-aos="fade-down" data-aos-delay="750">A</div>
                    </div>
                     </div> */}
                </span>
                <FilterPage />

                <div className='lg:w-[944px] mt-7 lg:mt-0 z-20 flex justify-between items-center'>
  <div className='text-gray-400 p-1 flex gap-1'>
    <a 
      href="https://www.instagram.com/turkiyetkinlikleri/" 
      aria-label="Instagram Sayfamız" 
      target="_blank" 
      rel="noopener noreferrer"
      data-aos="fade-up" 
      className='text-lg w-7 h-7 flex justify-center items-center text-white rounded-full hover:text-white hover:bg-cyan-500 hover:cursor-pointer transition'>
      <FaInstagram />
    </a>
    <a  
      href="https://www.facebook.com/turkiyetkinlikleri"
      aria-label="Facebook Sayfamız"
      data-aos="fade-up" 
      data-aos-duration="1300" 
      target="_blank" 
      rel="noopener noreferrer"
      className='text-base w-7 h-7 flex justify-center items-center text-white rounded-full hover:text-white hover:bg-cyan-500 hover:cursor-pointer transition'>
      <FaFacebookF />
    </a>
    <a  
      href="https://www.linkedin.com/company/turkiyetkinlikleri"
      aria-label="LinkedIn Sayfamız"
      data-aos="fade-up" 
      data-aos-duration="1600" 
      target="_blank" 
      rel="noopener noreferrer"
      className='text-base w-7 h-7 flex justify-center items-center text-white rounded-full hover:text-white hover:bg-cyan-500 hover:cursor-pointer transition'>
      <FaLinkedinIn />
    </a>
    <a  
      href="https://twitter.com/turkiyetkinlikleri" 
      aria-label="Twitter Sayfamız" 
      data-aos="fade-up" 
      data-aos-duration="1900" 
      target="_blank" 
      rel="noopener noreferrer"
      className='text-base w-7 h-7 flex justify-center items-center text-white rounded-full hover:text-white hover:bg-cyan-500 hover:cursor-pointer transition'>
      <FaTwitter />
    </a>
  </div>
</div>


                <div className="absolute bottom-1 z-30 text-white text-4xl md:text-6xl lg:text-6xl animate-bounce">
                    <IoIosArrowRoundDown data-aos="fade" />
                </div>
            </div>
        </header>
    )
}

export default Header;
