import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from "react-icons/io";
const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
      <button
        onClick={scrollToTop}
        aria-label="başa dön" className={`fixed transition bottom-0 text-2xl p-2 right-7 lg:p-2 bg-cyan-500 text-white rounded-full shadow-lg hover:bg-cyan-600 ${showButton ? 'opacitiy-100 -translate-y-7' : 'opacity-0'}`}
      >
        <IoIosArrowUp />
      </button>
  );
};

export default ScrollToTopButton;
