import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, query, orderBy, getDoc, doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { toast } from 'react-hot-toast';
import Slider from '../slider/Slider';
import istanbul from '../../files/istanbul.png';
import Footer from '../footer/Footer';

function Blog() {
  const [posts, setPosts] = useState([]);
  // const [newComments, setNewComments] = useState({});
  const [user, setUser] = useState(null);

  const [newPost, setNewPost] = useState({ title: '', content: '', imageUrl: '' });
  const [selectedPost, setSelectedPost] = useState(null); // Seçilen blog gönderisi durumu
  // const [commentsToShow, setCommentsToShow] = useState(4); // Gösterilecek yorum sayısı
  const [isAdmin, setIsAdmin] = useState(false);
  const [visiblePosts, setVisiblePosts] = useState(5);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  // Blog gönderilerini getir

  const slides = [
    
    { image: istanbul, alt: 'Slide 1' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/kastel-istanbul-23716.jpg', alt: 'Slide 2' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/zorlu-psm-turkcell-sahnesi-98391.jpg', alt: 'Slide 3' },
  ];

  const fetchPosts = async () => {
    setLoading(true);
    try {
        const postsCollection = collection(db, 'posts');
        const postsQuery = query(postsCollection, orderBy('timestamp', 'desc'));
        const postsSnapshot = await getDocs(postsQuery);
        const postsList = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(postsList);
      } catch (error) {
        toast.error('Gönderiler getirilirken bir hata oluştu.');
      } finally {
        setLoading(false); // Loading durdur
      }
  };

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // Kullanıcının admin olup olmadığını kontrol et
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.role === 'admin');
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });

    fetchPosts();

    return () => unsubscribe();
  }, []);

  // Yorumları getir
  const fetchComments = async (postId) => {
    const commentsCollection = collection(db, 'posts', postId, 'comments');
    const commentsQuery = query(commentsCollection, orderBy('timestamp', 'desc'));
    const commentsSnapshot = await getDocs(commentsQuery);
    const commentsList = commentsSnapshot.docs.map(doc => doc.data());
    setPosts(posts.map(post => post.id === postId ? { ...post, comments: commentsList } : post));
  };

  // Yeni post değişikliklerini yönet
  const handlePostChange = (e) => {
    setNewPost({
      ...newPost,
      [e.target.name]: e.target.value,
    });
  };

  // Yeni postu gönder
  const handlePostSubmit = async () => {
    if (!user) {
      
      toast.error('Yeni bir blog yazısı ekleyebilmek için giriş yapmalısınız.');
      return;
    }

    if (!newPost.title.trim() || !newPost.content.trim()) {

      toast.error('Başlık ve içerik alanları boş bırakılamaz.');
      return;
    }

    try {
        await addDoc(collection(db, 'posts'), {
          ...newPost,
          author: user.email || 'Anonim',
          timestamp: new Date(),
        });
    
        setNewPost({ title: '', content: '', imageUrl: '' });

        fetchPosts();
        toast.success('Yeni blog yazısı başarıyla eklendi!');
      } catch (error) {
        toast.error('Blog yazısı eklenirken bir hata oluştu.');
      }
    };
 
  const handlePostClick = (postId) => {
    setSelectedPost(postId);
    fetchComments(postId);
  };
  const handleShowMore = () => {
    setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 5); // 5 tane daha post göster
    if (visiblePosts + 5 >= posts.length) {
      setShowAll(true); // Tüm postlar gösterildiyse "Daha Fazla Göster" butonunu gizle
    }
  };

  const handleShowLess = () => {
    setVisiblePosts(prevVisiblePosts => {
      const newVisiblePosts = Math.max( 5); // 5 posttan daha azını göstermemek için kontrol et
      if (newVisiblePosts < posts.length) {
        setShowAll(false); // Daha fazla göster butonunu geri getir
      }
      return newVisiblePosts;
    });
  };
  const handleShowAll = () => {
    setVisiblePosts(posts.length);
  };
 
  const handleDeletePost = async (postId) => {
    if (!user || !isAdmin) {

      toast.error('Bu işlemi gerçekleştirebilmek için admin olmanız gerekmektedir.')
      return;
    }
  
    try {
        
      await deleteDoc(doc(db, 'posts', postId));
      setPosts(posts.filter(post => post.id !== postId));
      setSelectedPost(null);
      toast.success('Blog Yazısı Başarıyla Kaldırıldı.')
       // Silindikten sonra seçili postu sıfırla
    } catch (error) {

      toast.error('Blog gönderisi silinirken bir hata oluştu.')
    }
  };
  const renderPosts = () => {
    if (selectedPost) {
      const post = posts.find(post => post.id === selectedPost);
      if (!post) return <p>Blog gönderisi bulunamadı.</p>;
  
      return (
        <div key={post.id} className="bg-white p-6 rounded-lg shadow-md mb-4">
          <h2 className="text-2xl font-semibold">{post.title}</h2>
          <div className='w-full h-96'><img src={post.imageUrl} alt={post.title} className="w-full h-full object-cover mt-4 mb-4 rounded-lg" /></div>
          <p className="mt-4">{post.content}</p>
      
        </div>
      );
    }
  
    return (
        <div>
        {posts.slice(0, visiblePosts).map(post => (
          <div key={post.id} className="bg-white p-6 rounded-lg shadow-md mb-6 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">{post.title}</h2>
            <img src={post.imageUrl} alt={post.title} className="w-full h-96 mb-4 rounded-md object-cover" />
            <p className="text-gray-700 mb-4 line-clamp-3 px-4">{post.content}</p>
            <div className="flex items-center gap-2">
              <button
                onClick={() => handlePostClick(post.id)}
                className="bg-cyan-500 text-white py-2 px-4 rounded hover:bg-cyan-600 transition-colors duration-300"
              >
                İncele
              </button>
              {isAdmin && (
                <button
                  onClick={() => handleDeletePost(post.id)}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                >
                  Gönderiyi Kaldır
                </button>
              )}
            </div>
          </div>
        ))}
        <div className="mt-6 flex justify-center p-4">
          {!showAll && posts.length > visiblePosts && (
            <button
              onClick={handleShowMore}
              className="w-52 mx-1 border border-cyan-500 hover:border-cyan-600 bg-cyan-500 hover:bg-cyan-600 text-white py-2 px-4 rounded"

            >
              Daha Fazla Göster
            </button>
          )}
          {visiblePosts > 5 && (
            <button
              onClick={handleShowLess}
              className="py-2 px-4 w-52 mx-1 rounded bg-transparent text-cyan-500 border border-cyan-500 hover:bg-cyan-500 hover:text-white"

            >
              Daha Az Göster
            </button>
          )}
        </div>
      </div>
      
    );
  };
  

  return (
    <div className='w-full bg-gray-200'>
        <div className='mt-14'>
      <Slider slides={slides}/>
      </div>
        <div className="max-w-7xl mx-auto px-4 py-4">
      <h1 className="text-3xl text-center font-bold mb-6 mt-2">Blog</h1>
      
  
      {loading ? (
        // Yüklenme durumunda gösterilecek içerik
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      ) : (<>
      {user && isAdmin && (
        <div className="mb-8 bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-2">Yeni Blog Yazısı Ekle</h2>
          <input
            type="text"
            name="title"
            value={newPost.title}
            onChange={handlePostChange}
            placeholder="Başlık"
            className="w-full p-2 mb-2 border border-gray-300 rounded focus:border-cyan-500 focus:ring-0 focus:outline-none"
            />
          <textarea
            name="content"
            value={newPost.content}
            onChange={handlePostChange}
            placeholder="İçerik"
            className="w-full p-2 mb-2 border border-gray-300 rounded focus:border-cyan-500 focus:ring-0 focus:outline-none"          />
          <input
            type="text"
            name="imageUrl"
            value={newPost.imageUrl}
            onChange={handlePostChange}
            placeholder="Görsel URL"
          className="w-full p-2 mb-2 border border-gray-300 rounded focus:border-cyan-500 focus:ring-0 focus:outline-none"
          />
          <button
            onClick={handlePostSubmit}
            className="bg-cyan-500 text-white py-2 px-4 rounded hover:bg-cyan-600"
          >
            Blog Yazısını Ekle
          </button>
        </div>
      )}
  
      <div className="lg:flex-row flex flex-col gap-4">
        <div className="lg:w-1/4 w-full p-4 h-full bg-gray-50 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-2">Son Yazılar</h3>
          <button
            onClick={handleShowAll}
            className="block w-full mb-2 p-2 truncate bg-cyan-500 text-white rounded hover:bg-cyan-600"
          >
            Tümünü Görüntüle
          </button>
          {posts.slice(0, visiblePosts).map(post => (
            <button
              key={post.id}
              onClick={() => handlePostClick(post.id)}
              className="flex gap-2 items-center text-left w-full mb-2 p-2 bg-gray-200 rounded hover:bg-gray-300"
            >
             <div className='h-14 w-14 overflow-hidden flex-shrink-0'>
  <img src={post.imageUrl} alt="" className='w-full h-full object-cover rounded' />
</div>
              <div className='w-full overflow-hidden'>
              <p className='font-medium line-clamp-1'>{post.title}</p>
              <p className='text-xs text-gray-600 line-clamp-2'>{post.content}</p>
              </div>
            </button>
          ))}
          <div className="mt-2">
            {!showAll && posts.length > visiblePosts && (
              <button
                onClick={handleShowMore}
                className="block w-full mb-2 p-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Daha Fazla Göster
              </button>
            )}
            {visiblePosts > 5 && (
              <button
              onClick={handleShowLess}
              className="block w-full mb-2 p-2 bg-transparent text-gray-500 border border-gray-500 rounded hover:bg-gray-500 hover:text-white "
            >
              Daha Az Göster
            </button>
            
            )}
          </div>
        </div>
        <div className="lg:w-3/4 w-full">
          {renderPosts()}
        </div>
      </div>

</>)}

    </div>
    <Footer/>
    </div>
  );
  
}

export default Blog;
