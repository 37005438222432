import React, { useState, useEffect, useRef, useCallback } from 'react';

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideIntervalTime = 3000; // 3 saniye aralık
  const slideIntervalRef = useRef(null);

  const startSlider = useCallback(() => {
    slideIntervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    }, slideIntervalTime);
  }, [slides.length]);

  const stopSlider = useCallback(() => {
    if (slideIntervalRef.current) {
      clearInterval(slideIntervalRef.current);
    }
  }, []);

  useEffect(() => {
    startSlider();
    return () => {
      stopSlider();
    };
  }, [startSlider, stopSlider]);

  return (
    <div className="relative w-full mx-auto">
      <div className="overflow-hidden">
        <div
          className="whitespace-nowrap h-[300px] transition-transform duration-500"
          style={{ transform: `translateX(${-currentIndex * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div key={index} className="inline-block w-full">
              <img src={slide.image} alt={slide.alt} className="w-full h-[300px] object-cover" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
