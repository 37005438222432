import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, onSnapshot, getDocs } from 'firebase/firestore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { GrMapLocation, GrLocation } from 'react-icons/gr';
import { MdOutlineDateRange } from 'react-icons/md';
import Footer from '../footer/Footer';
import Slider from '../slider/Slider';
import istanbul from '../../files/istanbul.png'; // Dosya yolunu doğru şekilde belirtin
import { db } from '../../firebase/Firebase';

const cities = [
  "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Aksaray", "Amasya", "Ankara", "Antalya",
  "Artvin", "Aydın", "Balıkesir", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur",
  "Bursa", "çanakkale", "çankırı", "çorum", "Denizli", "Diyarbakır", "Düzce", "Edirne",
  "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane",
  "Hakkari", "Hatal", "Iğdır", "Isparta", "istanbul", "izmir", "Kahramanmaraş", "Karabük",
  "Karaman", "Kars", "Kastamonu", "Kayseri", "Kırıkkale", "Kırklareli", "Kırşehir",
  "Kilis", "Konya", "Kocaeli", "Kütahya", "Malatya", "Manisa", "Mardin", "Mersin",
  "Muğla", "Muş", "Nevşehir", "Niğde", "Ordu", "Osmaniye", "Rize", "Sakarya",
  "Samsun", "şanlıurfa", "şırnak", "Sinop", "Sivas", "şile", "Tekirdağ", "Tokat",
  "Trabzon", "Tunceli", "Uşak", "Van", "Yalova", "Yozgat", "Zonguldak"
].sort() 


const FilteredEventsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]); 

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories')); // 'categories' koleksiyonunu değiştirin
        const categoriesData = querySnapshot.docs.map(doc => doc.data().name); // Kategorilerin adlarını al
        setCategories(categoriesData);
      } catch (error) {
        console.error('Kategoriler alınamadı: ', error);
      }
    };

    fetchCategories();
  }, []);
  const [filters, setFilters] = useState({
    category: '',
    city: '',
    price: '',
    date: '',
  });
  const [searchCity, setSearchCity] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    // URL'den gelen sorgu parametrelerini al
    const queryParams = new URLSearchParams(location.search);
    setFilters({
      category: queryParams.get('category') || '',
      city: queryParams.get('city') || '',
      price: queryParams.get('price') || '',
      date: queryParams.get('date') || '',
    });
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const queryParams = new URLSearchParams(location.search);
        const category = (queryParams.get('category') || '').toLowerCase();
        const city = (queryParams.get('city') || '').toLowerCase();
        const price = queryParams.get('price') || '';
        const date = queryParams.get('date') || ''; // Tarih filtresini al
  
        const eventsRef = collection(db, "etkinlikler");
        const q = query(eventsRef);
  
        // Firestore koleksiyon dinleyicisi ekleyin
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const fetchedEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
          // Filtreleme işlemi
          const filteredEvents = fetchedEvents.filter(event => {
            const eventCategory = (event.category || '').toLowerCase();
            const eventCity = (event.city || '').toLowerCase();
            const eventDates = event.dates ? event.dates.map(date => date.toDate()) : []; // Tarihi al ve Date objesine çevir
  
            const matchCategory = category ? eventCategory.includes(category) : true;
            const matchCity = city ? eventCity.includes(city) : true;
            const matchPrice = price ? parseInt(event.price) <= parseInt(price) : true;
            const matchDate = date ? eventDates.some(eventDate => {
              const eventDateStr = eventDate.toISOString().split('T')[0]; // YYYY-MM-DD formatına çevir
              return eventDateStr === date;
            }) : true;
            return matchCategory && matchCity && matchPrice  && matchDate;
          });
  
          setEvents(filteredEvents);
          setLoading(false);
        });
  
        // Temizleme işlevi
        return () => unsubscribe();
  
      } catch (error) {
        setError('Veriler alınırken bir hata oluştu.');
        console.error("Hata: ", error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [location.search]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  
  const slides = [
    
    { image: istanbul, alt: 'Slide 1' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/kastel-istanbul-23716.jpg', alt: 'Slide 2' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/zorlu-psm-turkcell-sahnesi-98391.jpg', alt: 'Slide 3' },
  ];
  const handleSearch = (e) => {
    e.preventDefault()
    const searchParams = new URLSearchParams();
    if (filters.category) searchParams.append('category', filters.category);
    if (filters.city) searchParams.append('city', filters.city);
    if (filters.price) searchParams.append('price', filters.price);
    if (filters.date) searchParams.append('date', filters.date);

    navigate(`?${searchParams.toString()}`);
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleCitySearch = (e) => {
    setSearchCity(e.target.value);
  };

  const handleCitySelect = (city) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: city.toLowerCase()
    }));
    setSearchCity(city); // Seçilen şehri inputa yansıt
    setDropdownOpen(false);
  };
  
  const filteredCities = cities.filter(city =>
    city.toLowerCase().includes(searchCity.toLowerCase())
  );

  return (
    <div>
      <div className='mt-14'>
      <Slider slides={slides}/>
      </div>
      <div className='max-w-7xl mx-auto'>
      {/* Filtreleme Kriterlerini Göster */}

      {/* Filtreleme Bölümü */}
      <div className='p-4'>
      <div className='p-4 bg-gray-100 rounded-t-lg justify-center flex relative mt-10'>
        <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4 items-end'>
        <div>
        <label className="block text-center text-sm font-bold leading-6 mb-1 text-gray-500" htmlFor="category">
          Kategori
        </label>
        <select
          name="category"
          value={filters.category}
          onChange={handleInputChange}
          className="block w-52 rounded-full border-0 z-50 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        >
          <option value="">Kategori Seçin</option>
          {categories.map((category, index) => (
            <option key={index} value={category.toLowerCase()}>{category}</option>
          ))}
        </select>
      </div>
        <div className>
        <label className="block text-sm text-center font-bold leading-6  mb-1 text-gray-500" htmlFor="city">
          Şehir
        </label>
        <input
          type="text"
          placeholder="Şehir Ara"
          value={searchCity}
          onChange={handleCitySearch}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="block w-52 rounded-full border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        />
        {dropdownOpen && (
          <div className="absolute border border-gray-300 z-50 w-52 bg-white mt-1 max-h-60 overflow-y-auto rounded-md shadow-lg" ref={dropdownRef}>
            {filteredCities.length > 0 ? (
              filteredCities.map((city) => (
                <div
                  key={city}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                  onClick={() => handleCitySelect(city)}
                >
                  {city}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">Şehir bulunamadı.</div>
            )}
          </div>
        )}
      </div>
      <div >
        <label className="block text-sm text-center font-bold leading-6  mb-1 text-gray-500" htmlFor="price">
          Fiyat
        </label>
        <select
          name="price"
          value={filters.price}
          onChange={handleInputChange}
          className="block w-52 border-0 rounded-full z-50 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
        >
          <option value="">Fiyat Seçin</option>
          <option value="100">100₺ ve altı</option>
          <option value="200">200₺ ve altı</option>
          <option value="300">300₺ ve altı</option>
          {/* Daha fazla fiyat seçeneği ekleyebilirsiniz */}
        </select>
      </div>
      <div>
  <label className="block text-sm text-center font-bold leading-6 mb-1 text-gray-500" htmlFor="date">
    Tarih
  </label>
  <input
    type="date"
    name="date"
    value={filters.date}
    onChange={(e) => handleInputChange(e)}
    placeholder="Tarih seçin"
    className="block w-52 rounded-full border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
  />
</div>

      <button
        onClick={handleSearch}
        className='bg-cyan-500 hover:cursor-pointer hover:bg-cyan-600 transition  truncate h-10 text-white font-bold p-2 px-4 rounded-full'
      >
        ETKİNLİK ARA
      </button>
        </div>
      </div>
      <div className='p-4 bg-white border border-gray-200 rounded-b-lg flex flex-col lg:flex-row items-center gap-3'>
  <h2 className='text-lg font-semibold text-gray-800 lg:text-left text-center'>Uygulanan Filtreler:</h2>
  <ul className='flex flex-col lg:flex-row gap-x-4 gap-y-2 pb-1 list-none items-center'>
    {filters.category && (
      <li className='border border-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm'>
        Kategori: {filters.category}
      </li>
    )}
    {filters.city && (
      <li className='border border-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm'>
        Şehir: {filters.city}
      </li>
    )}
    {filters.price && (
      <li className='border border-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm'>
        Fiyat: {filters.price}TL
      </li>
    )}
    {filters.date && (
      <li className='border border-gray-300 text-gray-800 px-3 py-1 rounded-full text-sm'>
        Tür: {filters.date}
      </li>
    )}
  </ul>
</div>


      {/* Etkinlikler Listesi */}
      <div className='gap-2 justify-center flex-col items-center bg-ss text-sm mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-4 my-10'>
        {events.length > 0 ? (
          
          events.map(event => { 
            const id = event.id;
            const firstDate = event.dates && event.dates.length > 0 ? event.dates[0].toDate() : null;
            return (
            
            <div className='shadow-md rounded-xl relative  overflow-hidden bg-gray-50' key={event.id}>
              <div>
                <div className='h-52 rounded-t-xl w-full overflow-hidden'>
                  <img src={event.imageUrl} alt="" className='w-full h-full object-cover' />
                </div> 
                <div className='p-4 w-full h-60'>
                  <div className='flex justify-start items-center gap-x-4'>
                  <div className="flex items-center gap-x-4 text-sm">
                {firstDate && (
                <time dateTime={firstDate.toLocaleDateString()} className="text-gray-500 gap-x-4 flex flex-col items-center justify-center gap-1">
                    <div className='flex items-center gap-4'>
                        <div className='flex items-center gap-1'>
                            <MdOutlineDateRange />
                            <p>{firstDate.toLocaleDateString()}</p>
                        </div>
                    </div>
                </time>
            )}
                 
                </div>
                    <button className="relative z-10 rounded-full truncate bg-cyan-500 px-3 py-1 font-semibold text-white hover:bg-cyan-600">
                      {event.category}
                    </button>
                  </div>
                  <h3 className="mt-3 text-lg line-clamp-1 font-bold leading-6 text-gray-900 group-hover:text-gray-600">
                    {event.title}
                  </h3>
                  <div className="group relative">
                    <p className="mt-3 line-clamp-2 text-md leading-6 text-gray-600">{event.generalInfo}</p>
                  </div>
                  <div className='text-sm text-gray-500 flex gap-4 justify-start  items-center absolute bottom-16  left-4'>
                  <p className='flex justify-start items-center truncate gap-1'><GrLocation />{event.city}</p>
                  <p className='flex justify-start items-center truncate gap-1'><GrMapLocation />{event.venue}</p>
                </div>
                {/* İncele Butonu */}
                <button
                  onClick={() => navigate(`/event/${id}`)}
                  className='bg-transparent text-cyan-500 border border-cyan-500 px-4 py-1 rounded font-medium text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors duration-300 absolute bottom-4 left-4'
                >
                  İncele
                </button>
                <p className="absolute right-4 px-1 py-1 bottom-4 text-sm font-bold text-cyan-500">{event.price ? `${event.price} TL` : 'Ücretsiz'}</p>
                </div>
              </div>
            </div>
          )})
        ) : (
          <p>Etkinlik bulunamadı.</p>
        )}
      </div>
      </div>
      
    </div>
    <div className="w-full">
    <Footer className="max-w-7xl"/></div>
    </div>
  );
};

export default FilteredEventsPage;
