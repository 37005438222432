import React, { useState } from 'react'
import { doSignInWithEmailAndPassword } from '../../firebase/auth';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import img from '../../files/logo.png'
import { signInWithPopup } from 'firebase/auth';
import { FcGoogle } from "react-icons/fc";
import {  serverTimestamp, doc, getDoc, setDoc } from 'firebase/firestore';
import { db,googleProvider ,auth} from '../../firebase/Firebase';


function Login() {

  const loginErrorMessages = {
    "auth/invalid-email": "Geçersiz e-posta adresi.",
    "auth/user-disabled": "Bu kullanıcı devre dışı bırakılmış.",
    "auth/user-not-found": "Bu e-posta adresiyle kayıtlı kullanıcı bulunamadı.",
    "auth/wrong-password": "Yanlış şifre. Lütfen tekrar deneyin.",
    "auth/too-many-requests": "Çok fazla giriş denemesi yapıldı. Lütfen daha sonra tekrar deneyin.",
    "auth/network-request-failed": "Ağ bağlantısı hatası. Lütfen internet bağlantınızı kontrol edin.",
    "auth/missing-email": "E-posta adresi eksik.",
    "auth/missing-password": "Şifre eksik.",
    "auth/invalid-credential": "Geçersiz kimlik bilgisi."
  };

  const getLoginErrorMessage = (errorCode) => {
    return loginErrorMessages[errorCode] || "Giriş sırasında bilinmeyen bir hata oluştu. Lütfen tekrar deneyin.";



  };

  

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setSigningIn] = useState(false)


  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setSigningIn(true);
      try {
        await doSignInWithEmailAndPassword(email, password);
        toast.success('Başarıyla giriş yaptınız.')
      } catch (error) {

        const errorMessage = getLoginErrorMessage(error.code);
        toast.error(errorMessage)
      }
      setSigningIn(false);
    }
  };


  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        // Kullanıcının UID'sini document ID olarak kullanarak veriyi Firestore'a ekleyin veya güncelleyin
        const userDocRef = doc(db, 'users', user.uid); // UID'yi document ID olarak kullan
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid: user.uid,
                email: user.email,
                name: user.displayName || '',
                role: 'user',  // Varsayılan rol
                createdAt: serverTimestamp()
            });
            toast.success('Google hesabı ile giriş yapıldı ve kullanıcı bilgileri kaydedildi.');
        } else {
            toast.success('Google hesabı ile giriş yapıldı.');
        }
    } catch (error) {
        console.error('Google ile giriş yapılamadı!', error);
        toast.error('Google ile giriş yapılamadı!');
    }
};


  return (
    <div className='h-screen w-full'>
  
      <div className="flex h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src={img}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
            Hesabınızda oturum açın
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form action="#" method="POST" className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500">
                E-posta adresi
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-500">
                  Parola
                </label>
                <div className="text-sm">
                  <Link to={'/forgot-password'} className="font-semibold text-cyan-500 hover:text-cyan-600">
                    Şifrenizi mi unuttunuz?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            

            <div>
              <button
                type="submit"
                onClick={onSubmit}
                className="flex w-full justify-center rounded-md bg-cyan-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500"
              >
                OTURUM AÇ
              </button>
            </div>

            <div>
              <button
                onClick={handleGoogleSignIn}  // Google ile giriş fonksiyonunu çağırır
                className="flex w-full items-center justify-center rounded-md outline-none text-sm bg-gray-100 border font-semibold border-gray-300 py-1.5 text-gray-900 shadow-sm hover:bg-gray-200 focus:bg-gray-300"
                >
                <FcGoogle className="h-5 w-5 mr-2"/>
                Google ile Giriş Yap
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Üye değil misiniz?{' '}

            <Link to={'/register'} className="font-semibold leading-6 text-cyan-500 hover:text-cyan-600">
              Hemen üye ol</Link>

          </p>
        </div>
      </div>
    </div>
  )
}

export default Login