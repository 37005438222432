import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animasyon süresi
    });
  }, []);

  return (
    <footer className="bg-gray-200 text-black py-20">
      <div className="max-w-7xl mx-auto px-4">
        {/* Footer Başlığı ve Sosyal Medya İkonları */}
        <div className="flex justify-between items-center border-b-[1px] border-b-gray-300 pb-6">
          <span data-aos="fade-left" className="text-3xl font-bold">Türkiye Etkinlikleri</span>
          <div className="flex space-x-4">
            <a href="https://facebook.com" data-aos="fade-down" data-aos-delay="200" target="_blank" rel="noopener noreferrer" className="text-black hover:text-cyan-500 transition-colors">
              <FaFacebook size={24} />
            </a>
            <a href="https://twitter.com" data-aos="fade-down" data-aos-delay="400" target="_blank" rel="noopener noreferrer" className="text-black hover:text-cyan-500 transition-colors">
              <FaTwitter size={24} />
            </a>
            <a href="https://www.instagram.com/turkiyetkinlikleri/" data-aos="fade-down" data-aos-delay="600" target="_blank" rel="noopener noreferrer" className="text-black hover:text-cyan-500 transition-colors">
              <FaInstagram size={24} />
            </a>
            <a href="https://linkedin.com" data-aos="fade-down" data-aos-delay="800" target="_blank" rel="noopener noreferrer" className="text-black hover:text-cyan-500 transition-colors">
              <FaLinkedin size={24} />
            </a>
          </div>
        </div>

        {/* Footer Orta Bölüm: Açıklama ve Bağlantılar */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:gap-8">
          <div className="font-medium text-gray-500 text-justify my-6">
            Türkiye'deki ücretsiz etkinlikleri keşfetmenizi sağlayan bir platformdur. Konserler, festivaller, sergiler ve daha fazlasını kolayca bulabileceğiniz bu site ile tüm etkinlikler elinizin altında!
          </div>

          {/* Bağlantılar Listesi */}
          
        </div>
        <div className="flex justify-between">
        <div className="flex flex-col gap-2 text-gray-500">
            <Link to="/takvim" className="hover:text-cyan-500 font-medium  transition-colors">Takvim</Link>
            <Link to="/artist" className="hover:text-cyan-500 font-medium transition-colors">Sanatçılar</Link>
            <Link to="/blog" className="hover:text-cyan-500 font-medium transition-colors">Blog</Link>
            <Link to="/sss" className="hover:text-cyan-500 font-medium transition-colors">SSS</Link>
          </div>
          <div className="flex flex-col items-start gap-2">
            <Link to="/privacy-policy" className="text-gray-500 font-medium hover:text-cyan-500 transition-colors">
              Gizlilik Politikası
            </Link>
            <Link to="/cookie-policy" className="text-gray-500 font-medium hover:text-cyan-500 transition-colors">
              Çerez Politikası
            </Link>
            <a href="mailto:turkiyetkinlikleri@gmail.com" className="text-gray-500 font-medium hover:text-cyan-500 transition-colors">
              İletişim
            </a>
          </div>
        </div>
        {/* Footer Alt Bölüm: Gizlilik Politikası ve İletişim */}
        <div className="flex sm:flex-row flex-col justify-between items-center md:text-left mt-8">
          
          <p className="font-medium text-gray-500 mt-2 sm:mt-0">
            © 2024 Tüm Hakları Saklıdır.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
