import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';
import { GrLocation } from 'react-icons/gr';
import { MdOutlineDateRange } from 'react-icons/md';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// Import DatePicker
const Etkinlikler = () => {
  const { city } = useParams();
  const [etkinlikler, setEtkinlikler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arama, setArama] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const etkinlikleriGetir = async () => {
      const etkinliklerRef = collection(db, 'etkinlikler');
      const etkinlikSorgusu = query(
        etkinliklerRef,
        where('city', '==', city)
      );
      const querySnapshot = await getDocs(etkinlikSorgusu);
      const etkinliklerData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dates: data.dates.map(date => date.toDate()) // Tarihleri Date objesine dönüştür
        };
      });

      // Etkinlikleri tarihe göre sıralama (en yakın tarih üstte)
      etkinliklerData.sort((a, b) => {
        const tarihA = a.dates.length > 0 ? a.dates[0] : new Date(0);
        const tarihB = b.dates.length > 0 ? b.dates[0] : new Date(0);
        return tarihA - tarihB;
      });

      setEtkinlikler(etkinliklerData);
      setLoading(false);
    };

    etkinlikleriGetir();
  }, [city]);

  if (loading) {
    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>;
  }

  const filtrelenmisEtkinlikler = etkinlikler.filter((etkinlik) =>
    etkinlik.title.toLowerCase().includes(arama.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-200 pt-[72px]">
      <div className='max-w-7xl mx-auto p-4'>
        {/* Geri Dön Butonu */}
      
        <h1 className="text-xl font-bold mb-2 text-center lg:text-left">{city} Şehrindeki Etkinlikler</h1>
        
        <input
          type="text"
          value={arama}
          onChange={(e) => setArama(e.target.value)}
          placeholder="Etkinlik ara..."
          className="block w-full rounded-full mb-6 sm:w-[640px] lg:w-full mx-auto text-gray-900 ring-0 focus:ring-2 focus:ring-cyan-500 border-none placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />

<div className="mx-auto grid max-w-2xl grid-cols-2 sm:grid-cols-3 sm:p-0 gap-x-4 gap-y-4 lg:mx-0 lg:max-w-none lg:text-sm lg:grid-cols-6 lg:p-0">
  {filtrelenmisEtkinlikler.length > 0 ? (
    filtrelenmisEtkinlikler.map((etkinlik) => {
      const enYakinTarih = etkinlik.dates.length > 0 ? etkinlik.dates[0] : null;

      return (
        <article key={etkinlik.id} className="flex flex-col items-start rounded-xl bg-gray-50 shadow-md relative">
          {etkinlik.imageUrl ? (
            <div className='rounded-t-xl h-40 w-full overflow-hidden hover:cursor-pointer' onClick={() => navigate(`/event/${etkinlik.id}`)}>
              <img className='w-full h-full hover:scale-105 transition object-top object-cover' src={etkinlik.imageUrl} alt={etkinlik.title} />
            </div>
          ) : (
            <div className='rounded-t-xl w-full overflow-hidden'>
              <img className='w-full h-full object-cover' src={etkinlik.purchaseUrl || etkinlik.imageUrl} alt={etkinlik.title} />
            </div>
          )}
          <div className='p-3 w-full h-[134px] relative overflow-hidden'>
            <div className='flex gap-1 items-center text-gray-500'><MdOutlineDateRange />
              {enYakinTarih ? (
                <p>{enYakinTarih.toLocaleDateString()}</p> // Tarihi formatla
              ) : (
                <p>Geçersiz tarih</p>
              )}
            </div>
            <span className="mt-1 text-sm font-bold leading-6 line-clamp-1 text-gray-900 group-hover:text-gray-600">
              <span className="absolute inset-0" />
              {etkinlik.title}
            </span>

            {/* Konum ve Mekan Bilgileri */}
            <div className='text-sm text-gray-500 justify-start items-center'>
              <div className='flex justify-start items-center gap-1 mt-1'>
                <GrLocation /><p className='flex justify-start items-center gap-1'>{etkinlik.city}</p>
              </div>
            </div>
            {/* İncele Butonu */}
            <button
              onClick={() => navigate(`/event/${etkinlik.id}`)}
              className='bg-transparent text-cyan-500 border border-cyan-500 px-4 py-1 rounded font-medium text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors duration-300 absolute bottom-3 left-3'
            >
              İncele
            </button>
            <p className="absolute right-3 px-1 py-1 bottom-3 text-sm font-bold text-cyan-500">{etkinlik.price ? `${etkinlik.price} TL` : 'Ücretsiz'}</p>
          </div>
        </article>
      );
    })
  ) : (
    <p className="text-center text-gray-700 text-lg absolute">Bu şehirde henüz etkinlik bulunmuyor.</p>
  )}
</div>

      </div>
    </div>
  );
};

export default Etkinlikler;
