import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../../firebase/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const firestore = getFirestore();

    useEffect(() => {
        async function initializeUser(user) {
            setLoading(true);
            try {
                if (user) {
                    setCurrentUser({ ...user });
                    setUserLoggedIn(true);
    
                    const userDoc = doc(firestore, 'users', user.uid);
                    const userSnapshot = await getDoc(userDoc);
    
                    if (userSnapshot.exists()) {
                        const userData = userSnapshot.data();
                        setCurrentUser(prev => ({ ...prev, role: userData.role }));
                    } else {
                        console.log('No user data found for this UID');
                    }
                } else {
                    setCurrentUser(null);
                    setUserLoggedIn(false);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        }
    
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, [firestore]);
    

    const value = {
        currentUser,
        userLoggedIn,
        role: currentUser?.role, 
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
