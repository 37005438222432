import React from 'react'
import FAQ from './FAQ'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Slider from '../slider/Slider';
import istanbul from '../../files/istanbul.png'
const slides = [
    
    { image: istanbul, alt: 'Slide 1' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/kastel-istanbul-23716.jpg', alt: 'Slide 2' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/zorlu-psm-turkcell-sahnesi-98391.jpg', alt: 'Slide 3' },
  ];
function sss() {
  return (
    <div>
        <Navbar/>
        <div className='mt-14'>
      <Slider slides={slides}/>
      </div>
        <FAQ/>
        <Footer/>
    </div>
  )
}

export default sss