import React from "react";
import { Helmet } from "react-helmet";

const CookiePolicy = () => {
  return (
    <div className="max-w-7xl mx-auto rounded-xl bg-white p-10">
      {/* SEO Uyumlu Meta Etiketler */}
      <Helmet>
        <title>Çerez Politikası</title>
        <meta
          name="description"
          content="Sitemizde kullanılan çerezler ve kullanıcı deneyimini geliştirmek için hangi tür çerezlerin kullanıldığını öğrenin."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="[https://www.siteniz.com/cerez-politikasi]" />
      </Helmet>

      {/* Başlık */}
      
      {/* Giriş Paragrafı */}
      <p className="mb-4">
        Turkiyetkinlikleri, kullanıcılarımızın Türkiye'deki etkinlikleri tek bir
        platformdan kolayca bulmalarını sağlamak amacıyla kurulmuştur. Bu sayfada, 
        sitemizde kullanılan çerezler hakkında bilgi bulabilirsiniz.
      </p>

      {/* Çerez Nedir? */}
      <h2 className="text-2xl font-semibold mb-4">1. Çerez Nedir?</h2>
      <p className="mb-4">
        Çerezler, bir web sitesini ziyaret ettiğinizde tarayıcınıza veya cihazınıza 
        yerleştirilen küçük metin dosyalarıdır. Çerezler, kullanıcıların web sitesini 
        nasıl kullandığını anlamamıza ve deneyimlerini iyileştirmemize yardımcı olur.
      </p>

      {/* Hangi Tür Çerezler Kullanılıyor */}
      <h2 className="text-2xl font-semibold mb-4">2. Hangi Tür Çerezleri Kullanıyoruz?</h2>
      <p className="mb-4">
        Sitemizde farklı türlerde çerezler kullanılmaktadır. Bu çerezler şunlardır:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li className="mb-2">
          <strong>Zorunlu Çerezler:</strong> Bu çerezler, sitenin temel işlevlerinin 
          düzgün çalışmasını sağlar.
        </li>
        <li className="mb-2">
          <strong>Performans ve Analiz Çerezleri:</strong> Bu çerezler, sitemizin nasıl 
          kullanıldığını anlamamıza ve geliştirmemize yardımcı olur.
        </li>
      </ul>

      {/* Çerezleri Nasıl Yönetebilirsiniz? */}
      <h2 className="text-2xl font-semibold mb-4">3. Çerezleri Nasıl Yönetebilirsiniz?</h2>
      <p className="mb-4">
        Tarayıcı ayarlarınızı değiştirerek çerezleri kabul edebilir veya reddedebilirsiniz. 
        Çerezleri devre dışı bırakmak, sitenin bazı bölümlerinin düzgün çalışmamasına neden olabilir.
      </p>

      {/* Üçüncü Taraf Çerezler */}
      <h2 className="text-2xl font-semibold mb-4">4. Üçüncü Taraf Çerezler</h2>
      <p className="mb-4">
        Web sitemiz, üçüncü taraf hizmet sağlayıcılar (örneğin Google Analytics) tarafından 
        yerleştirilen çerezler kullanabilir.
      </p>

      {/* Çerez Politikası Güncellemeleri */}
      <h2 className="text-2xl font-semibold mb-4">5. Çerez Politikası Güncellemeleri</h2>
      <p className="mb-4">
        Bu çerez politikası zaman zaman güncellenebilir. Bu sayfayı düzenli olarak ziyaret ederek 
        güncellemelerden haberdar olabilirsiniz.
      </p>

      {/* İletişim */}
      <h2 className="text-2xl font-semibold mb-4">6. İletişim</h2>
      <p>
        Çerez politikamızla ilgili sorularınız için bizimle turkiyetkinlikleri@gmail.com adresinden iletişime geçebilirsiniz.
      </p>
    </div>
  );
};

export default CookiePolicy;
