import React, { useCallback, useState } from 'react';
import { doCreateUserWithEmailAndPassword } from '../../firebase/auth';
import { useAuth } from '../../contexts/authContext';
import { Link, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import img from '../../files/logo.png';
import { doc, getDoc, serverTimestamp, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';
import { auth, googleProvider } from '../../firebase/Firebase';
import { signInWithPopup } from 'firebase/auth';
import { FcGoogle } from "react-icons/fc";


function Register() {
    const { userLoggedIn } = useAuth();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false); // Yeni state
    const now = Timestamp.now();

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
    
            // Kullanıcının UID'sini document ID olarak kullanarak veriyi Firestore'a ekleyin veya güncelleyin
            const userDocRef = doc(db, 'users', user.uid); // UID'yi document ID olarak kullan
            const userDoc = await getDoc(userDocRef);
    
            if (!userDoc.exists()) {
                await setDoc(userDocRef, {
                    uid: user.uid,
                    email: user.email,
                    name: user.displayName || '',
                    role: 'user',  // Varsayılan rol
                    createdAt: serverTimestamp()
                });
                toast.success('Google hesabı ile giriş yapıldı ve kullanıcı bilgileri kaydedildi.');
            } else {
                toast.success('Google hesabı ile giriş yapıldı.');
            }
        } catch (error) {
            console.error('Google ile giriş yapılamadı!', error);
            toast.error('Google ile giriş yapılamadı!');
        }
    };

    const getRegisterErrorMessage = useCallback((errorCode) => {
        const registerErrorMessages = {
            "auth/email-already-in-use": "Bu e-posta adresi zaten kullanımda.",
            "auth/invalid-email": "Geçersiz e-posta adresi.",
            "auth/operation-not-allowed": "Bu işlem şu anda geçersiz.",
            "auth/weak-password": "Şifreniz çok zayıf. Lütfen daha güçlü bir şifre belirleyin.",
            "auth/network-request-failed": "Ağ bağlantısı hatası. Lütfen internet bağlantınızı kontrol edin.",
            "auth/missing-email": "E-posta adresi eksik.",
            "auth/missing-password": "Şifre eksik.",
        };
    
        return registerErrorMessages[errorCode] || "Kayıt sırasında bilinmeyen bir hata oluştu. Lütfen tekrar deneyin.";
    }, []);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!isRegistering) {
            if (password !== confirmPassword) {
                toast.error('Parolalar eşleşmiyor.');
                return;
            }
    
            if (!name) {
                toast.error('Kullanıcı adı zorunludur.');
                return;
            }

            if (!isPrivacyPolicyAccepted) { // Gizlilik sözleşmesini onaylamış mı kontrol et
                // Diğer hatalar yoksa, sadece gizlilik sözleşmesi onaylanmamışsa hata mesajı göster
                if (!email && !password) { // Bir başka şart daha ekleyebilirsiniz
                    // Diğer alanlar eksikse, gizlilik sözleşmesi hata mesajını göstermeyin
                    toast.error('Gizlilik sözleşmesini kabul etmeniz gerekmektedir.');
                    return;
                }
            }

            setIsRegistering(true);
            try {
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);
                const user = userCredential.user; // Oluşturulan kullanıcıdan `uid` alınır
    
                await setDoc(doc(db, "users", user.uid), { // `setDoc` ile Firestore'a `uid` ile kullanıcıyı kaydediyoruz
                    uid: user.uid, // Kullanıcı ID'si
                    name: name,
                    email: email,
                    role: 'user', // Varsayılan olarak 'user' rolü eklenir
                    createdAt: now
                });
    
                toast.success('Başarıyla kayıt oldunuz.');
            } catch (error) {
                const errorMessage = getRegisterErrorMessage(error.code);
                toast.error(errorMessage);
            }
            setIsRegistering(false);
        }
    }, [email, name, password, confirmPassword, isRegistering, now, getRegisterErrorMessage, isPrivacyPolicyAccepted]); 

    return (
        <div className='h-screen w-full'>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        alt="Your Company"
                        src={img}
                        className="mx-auto h-10 w-auto"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
                        Hesap oluşturun
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form action="#" onSubmit={onSubmit} method="POST" className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-500">
                                Kullanıcı Adı
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    autoComplete="new-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500">
                                E-posta adresi
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-500">
                                Parola
                            </label>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    autoComplete="new-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-500">
                                Parolayı Onayla
                            </label>
                            <div className="mt-2">
                                <input
                                    id="confirm-password"
                                    name="confirm-password"
                                    type="password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    autoComplete="new-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <input
                                id="privacy-policy"
                                name="privacy-policy"
                                type="checkbox"
                                onChange={(e) => setIsPrivacyPolicyAccepted(e.target.checked)}
                                required
                                className="mr-2 h-4 w-4 text-cyan-500 focus:ring-cyan-500 border-gray-300 rounded"

                            />
                            <label htmlFor="privacy-policy" className="text-sm text-gray-600">
                                Gizlilik Sözleşmesini okudum ve kabul ediyorum. <a href="/privacy-policy" target="_blank" className="text-cyan-500 hover:underline ">Gizlilik Sözleşmesi</a>
                            </label>
                        </div>

                        <div>
                            <button
                                type="submit"
                                
                                className="flex w-full justify-center rounded-md bg-cyan-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-600 outline-none"
                            >
                                Kayıt Ol
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={handleGoogleSignIn} // Google ile giriş fonksiyonunu çağırır
                                className="flex w-full text-sm items-center justify-center rounded-md outline-none bg-gray-100 border font-semibold border-gray-300 py-1.5 text-gray-900 shadow-sm hover:bg-gray-200 focus:bg-gray-300"
                            >
                                <FcGoogle className="h-5 w-5 mr-2" />
                                Google ile Giriş Yap
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Zaten bir hesabınız var mı?{' '}
                        <Link to={'/login'} className="font-semibold leading-6 text-cyan-500 hover:text-cyan-600">Giriş Yap</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Register;
