import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase/Firebase';
import { collection, doc } from 'firebase/firestore';
import { GrMapLocation, GrLocation } from 'react-icons/gr';
import { MdOutlineDateRange } from 'react-icons/md';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../footer/Footer';
import { FaRegClock } from 'react-icons/fa';
import { FaShoppingCart } from 'react-icons/fa';


const EventDetail = () => {
  const eventsCollectionRef = collection(db, 'etkinlikler'); // Firestore koleksiyonu referansı
  const [events, isLoading] = useCollection(eventsCollectionRef);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [visibleCount,] = useState(5); // Başlangıçta gösterilecek etkinlik sayısı
  const navigate = useNavigate();
  const { id } = useParams(); // URL'den id parametresini al
  const [event, loading, error] = useDocumentData(doc(db, 'etkinlikler', id)); // Firestore'dan etkinliği al

  const [isExpanded, setIsExpanded] = useState(false); // Açıklamanın genişletilip genişletilmeyeceğini kontrol eden state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal'ın açık olup olmadığını kontrol eden state
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit'
  };
  const handleImageClick = () => {
    setIsModalOpen(true); // Resme tıklanıldığında modal'ı aç
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Modal'ı kapat
  };

  const handleBackdropClick = (e) => {
    // Modal içeriğine tıklanırsa hiçbir şey yapma
    if (e.target.id === 'modal-backdrop') {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (events && event) {
      // Seçili etkinliğin şehri
      const selectedCity = event.city;

      // Şehir filtrelemesi yaparak etkinlikleri sıralama
      const filteredEvents = events.docs.filter(doc => doc.data().city === selectedCity && doc.id !== id);

      // İlk başta gösterilecek etkinlikleri ayarla
      setDisplayedEvents(filteredEvents.slice(0, visibleCount));
    }
  }, [events, event, id, visibleCount]);

  const [visibleEvents, setVisibleEvents] = useState(5);

  const initialCount = 5;
  const increment = 5;
  const handleShowMore = () => {
    setVisibleEvents((prev) => prev + increment); // Her tıklamada 8 etkinlik daha göster
  };

  const handleShowLess = () => {
    setVisibleEvents((prev) => (prev > initialCount ? prev - increment : initialCount)); // Her tıklamada 8 etkinlik daha az göster
  };

  if (loading || isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ); // Veriler yüklenirken gösterilecek
  }


  if (error) {
    return <h1>Error: {error.message}</h1>; // Hata durumunda gösterilecek
  }

  if (!event) {
    return <h1>Event not found</h1>; // Etkinlik bulunamadığında gösterilecek
  }

  // Buton olup olmadığını kontrol et
  const hasButton = event.details.length > 100;

  // Toplam etkinlik sayısını hesapla
  const totalEvents = events ? events.docs.filter(doc => doc.data().city === event.city && doc.id !== id).length : 0;

  return (
    <div className='w-full min-h-screen bg-gray-100'>
      <div className="p-4 max-w-7xl mx-auto pt-20">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-1/4 h-full "><div className='shadow-md h-full  overflow-hidden bg-white rounded-xl ' >
            <img
              className="w-full h-96 object-cover rounded-xl hover:scale-105 transition cursor-pointer"
              src={event.imageUrl}
              alt={event.title}
              onClick={handleImageClick} // Resme tıklanıldığında modal'ı aç
            />
          </div>
            {event.artists.length > 0 ? <div className='mt-4 p-4 bg-white rounded-xl  shadow-md'>
              <p className='font-bold mb-4'>Sanatçılar</p>
              <div className='flex flex-col gap-3'>
                {event.artists.map(artist => (
                  <Link key={artist.id} to={`/artist/${artist.uid}`} className='flex justify-start truncate items-center gap-2 text-base text-gray-500'>
                    <img src={artist.imageUrl} alt={artist.name} className='h-10 w-10 rounded-lg object-cover' />
                    <div className='truncate'>{artist.name}</div>
                  </Link>
                ))}
              </div>
            </div> : ""}

          </div>
          <div className='w-full h-full'>
            <div className="w-full h-full bg-white rounded-xl p-6 flex flex-col justify-between shadow-md">
              <div>
                <h2 className="text-3xl font-bold line-clamp-1">{event.title}</h2>

                <div className='flex gap-4 truncate my-4'>
                  {event.dates && event.dates.length > 0 && (
                    <time dateTime={event.dates[0].toDate().toLocaleDateString()} className="text-gray-500 gap-x-4 flex flex-col items-center justify-center gap-1">
                      {event.dates.map((date, index) => (
                        <div key={index} className='flex items-center gap-4'>
                          <div className='flex items-center gap-1'>
                            <MdOutlineDateRange />
                            <p>{date.toDate().toLocaleDateString()}</p>
                          </div>
                          <div className='flex items-center gap-1'>
                            <FaRegClock />
                            <span>{date.toDate().toLocaleTimeString([], timeOptions)}</span>
                          </div>
                        </div>
                      ))}
                    </time>
                  )}

                </div>
                <button className='bg-transparent truncate mb-4 text-cyan-500 border border-cyan-500 px-4 py-1 rounded-full font-medium text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors duration-300'>
                  {event.category}
                </button>
                <div className="relative">


                  <div
                    className={`text-md px-4 border pt-1 pb-2 bg-gray-200 rounded-xl transition overflow-hidden ${isExpanded ? 'h-full' : 'max-h-[67px]'}`}
                  ><p className='font-semibold mt-1'>Etkinlik Detayı</p>

                    <p className='mt-1'>{event.generalInfo}</p>
                    <p className='mt-8'>
                      {event.details}</p>
                    <p className='mt-8'>
                      {event.notes}</p>
                  </div>
                  {!isExpanded && event.details.length > 100 && (
                    <button
                      onClick={() => setIsExpanded(true)}
                      className="absolute -bottom-9 z-30 text-white text-sm font-semibold mt-2 px-3 py-1 rounded bg-cyan-500 hover:bg-cyan-600"
                    >
                      DEVAMI
                    </button>
                  )}
                  {isExpanded && (
                    <button
                      onClick={() => setIsExpanded(false)}
                      className="absolute z-30 -bottom-9 text-white text-sm font-semibold mt-2 px-3 py-1 rounded bg-cyan-500 hover:bg-cyan-600"
                    >
                      DARALT
                    </button>
                  )}
                </div>
                <div className={`text-base ${hasButton ? 'mt-14' : 'mt-4'} text-gray-500 flex gap-4 justify-start items-center`}>
                  <p className='flex justify-start truncate items-center gap-1'><GrLocation /> {event.city}</p>
                  <p className='flex justify-start truncate items-center gap-1'><GrMapLocation /> {event.venue}</p>
                </div>

              </div>
              <div className='flex items-center mt-3 gap-3'>
                {event.price > 0 ? (event.purchaseUrl ? <a href={event.purchaseUrl} target='_blank' rel="noopener noreferrer" className="inline-flex truncate rounded cursor-pointer font-medium items-center px-3 py-1 text-white bg-cyan-500 hover:bg-cyan-600 shadow-sm">
                  <FaShoppingCart className="mr-2" /> {/* İkonun buton metninin sol tarafında görünmesini sağlar */}
                  Satın Al
                </a> : <a href={event.purchaseUrl} target='_blank' rel="noopener noreferrer" className="inline-flex truncate rounded cursor-not-allowed font-medium items-center px-3 py-1 text-gray-100 bg-gray-300  ">
                  <FaShoppingCart className="mr-2" /> {/* İkonun buton metninin sol tarafında görünmesini sağlar */}
                  Satın Al
                </a>) : ''}<p className='flex justify-start truncate font-bold text-cyan-500 items-center gap-1'>{event.price ? (
                  <>
                    {event.price} TL <span className='text-xs truncate'> 'den başlayan fiyatlarla</span>
                  </>
                ) : 'Ücretsiz'}</p>
              </div>
            </div>
            <div className='mt-6 mb-2 font-medium text-lg line-clamp-1'>Diğer {event.city} Etkinlikleri</div>
            <div className=' p-3 bg-white relative rounded-xl mx-auto grid shadow-md grid-cols-2 sm:grid-cols-3 gap-x-3 gap-y-3 lg:max-w-none lg:grid-cols-5'>
              {displayedEvents && displayedEvents.length > 0 ? (
                displayedEvents.map((doc) => {
                  const event = doc.data(); const id = doc.id;
                  const firstDate = event.dates && event.dates.length > 0 ? event.dates[0].toDate() : null;
                  return (

                    <article className="flex flex-col h-[136] items-start rounded-xl bg-gray-50 border relative">
                      {event.imageUrl ? (
                        <div className=' rounded-t-xl h-40 w-full overflow-hidden hover:cursor-pointer' onClick={() => navigate(`/event/${id}`)}>
                          <img className='w-full h-full hover:scale-105 transition  object-top object-cover' src={event.imageUrl} alt="" />
                        </div>
                      ) : (<div className=' rounded-t-xl w-full overflow-hidden'>
                        <img className='w-full h-full object-cover' src={event.imageUrl} alt="" />
                      </div>
                      )}
                      <div className='p-3 w-full h-[136px] relative overflow-hidden'><h3 className="mt-1 text-sm font-bold leading-6 truncate text-gray-900 group-hover:text-gray-600">
                        <span className="absolute inset-0" />
                        {event.title}
                      </h3>
                        <div className="flex items-center gap-x-4 text-sm">
                          {firstDate && (
                            <time dateTime={firstDate.toLocaleDateString()} className="text-gray-500 gap-x-4 flex flex-col items-center justify-center gap-1">
                              <div className='flex items-center gap-4'>
                                <div className='flex items-center gap-1'>
                                  <MdOutlineDateRange />
                                  <p>{firstDate.toLocaleDateString()}</p>
                                </div>
                              </div>
                            </time>
                          )}

                        </div>



                        {/* Konum ve Mekan Bilgileri */}
                        <div className='text-sm text-gray-500 justify-start items-center'>
                          <div className='flex justify-start items-center gap-1  mt-1'>
                            <GrLocation /><p className='flex justify-start items-center gap-1'>{event.city}</p>
                          </div>

                        </div>
                        {/* İncele Butonu */}
                        <button
                          onClick={() => navigate(`/event/${id}`)}
                          className='bg-transparent text-cyan-500 border border-cyan-500 px-4 py-1 rounded font-medium text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors duration-300 absolute bottom-3 left-3'
                        >
                          İncele
                        </button>
                        <p className="absolute right-3 px-1 py-1 bottom-3 text-sm font-bold text-cyan-500">{event.price ? `${event.price} TL` : 'Ücretsiz'}</p>
                      </div>
                    </article>
                  );
                })
              ) : (
                <p className=' top-3 left-4 col-span-5'>Bu şehirde şu anda daha fazla etkinlik bulunmamaktadır.</p>
              )}
            </div>
            <div className='flex justify-center mt-4 gap-4'>
              {visibleEvents > initialCount && (
                <button
                  onClick={handleShowLess}
                  className="rounded-md border border-cyan-500 w-52 bg-transparent px-4 py-2 text-sm font-semibold text-cyan-500 shadow-sm hover:bg-cyan-500 hover:text-white"
                >
                  Daha Az Göster
                </button>
              )}
              {visibleEvents < totalEvents && (
                <button
                  onClick={handleShowMore}

                  className="rounded-md bg-cyan-500 px-4 py-2 w-52 text-sm font-semibold text-white shadow-sm hover:bg-cyan-600"
                >
                  Daha Fazla Göster
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isModalOpen && (
        <div
          id='modal-backdrop'
          className='fixed inset-0   bg-black bg-opacity-50 flex items-center justify-center z-50'
          onClick={handleBackdropClick}
        >
          <div className='relative rounded-xl bg-white'>
            <button
              onClick={() => setIsModalOpen(false)}
              className='absolute top-4 right-4 text-white hover:opacity-80'
              aria-label='Close'
            >
              <svg
                className='w-6 h-6'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
            <img
              src={event.imageUrl}
              alt={event.title}
              className='max-w-[90vw] max-h-[90vh] object-contain rounded-xl'
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default EventDetail;
