import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';

export async function FetchEvents() {
    const eventsCollection = collection(db, 'etkinlikler');
  
    // Etkinlikleri tarihlerine göre sıralayın
    const q = query(eventsCollection, orderBy('dates')); // Artık dates dizisine göre sıralama yapıyoruz
    
    // Verileri getirin
    const querySnapshot = await getDocs(q);
  
    // Etkinlikleri tarihlere göre gruplayın
    const eventsByDate = querySnapshot.docs.reduce((acc, doc) => {
      const event = { id: doc.id, ...doc.data() };
      
      // Tüm tarihler üzerinden geçerek her biri için gruplama yapıyoruz
      event.dates.forEach(date => {
        const eventDate = new Date(date.toDate()).toLocaleDateString(); // Tarih formatını belirleyin
  
        // Tarih varsa, etkinliği bu tarihe ekleyin; yoksa, yeni bir dizi oluşturun
        if (!acc[eventDate]) {
          acc[eventDate] = [];
        }
        acc[eventDate].push(event);
      });
  
      return acc;
    }, {});
  
    return eventsByDate;
}
