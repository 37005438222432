import React, { useState, useEffect } from 'react';
import { FetchEvents } from './FetchEvents'; // Güncellenmiş işlevi import edin
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'; // Yönlendirme için Link bileşeni
import Slider from '../slider/Slider';
import istanbul from '../../files/istanbul.png';
import Footer from '../footer/Footer';

function CalendarPage() {
  const [eventsByDate, setEventsByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const slides = [
    
    { image: istanbul, alt: 'Slide 1' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/kastel-istanbul-23716.jpg', alt: 'Slide 2' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/zorlu-psm-turkcell-sahnesi-98391.jpg', alt: 'Slide 3' },
  ];
  useEffect(() => {
    async function loadEvents() {
      setLoading(true);
      try {
        const fetchedEvents = await FetchEvents();
        setEventsByDate(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    }

    loadEvents();
  }, []);

  return (
    <div>
      <div className='mt-14'>
      <Slider slides={slides}/>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="p-4 flex justify-center bg-gray-200">
        <div className="max-w-7xl w-full">
          <h1 className="text-3xl font-bold mb-6 mt-2 text-center text-black">Yaklaşan Etkinlikler</h1>
          <div className="space-y-6">
            {Object.entries(eventsByDate).map(([date, events]) => (
              <div key={date} className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4 border-b border-gray-200 pb-2">{date}</h2>
                <ul className="space-y-2">
                  {events.map((event) => (
                    <li key={event.id} className="border rounded flex items-center py-2 px-3 justify-between">
                      <div className="flex items-center gap-2">
                        <img src={event.imageUrl} alt={event.title} className="h-10 w-10 rounded object-cover" />
                        <div>
                          <h3 className="text-lg font-bold line-clamp-1 text-gray-900">{event.title}</h3>
                          <p className="text-gray-700 text-sm line-clamp-1">{event.description}</p>
                        </div>
                      </div>
                      <Link to={`/event/${event.id}`} className='bg-transparent text-cyan-500 border border-cyan-500 px-4 py-1 rounded duration-300 text-sm hover:bg-cyan-500 hover:text-white hover:border-transparent transition-colors bottom-4 left-4'
                      >
                        incele
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CalendarPage;
