import React from 'react';

import Footer from '../footer/Footer';

const PrivacyPolicy = () => {
  return (
    <div className='bg-gray-200 p-4'>
      <h1 className="text-3xl font-bold text-center mb-8 pt-20">Gizlilik Politikası | Türkiye Etkinlikleri</h1>

      <div className="max-w-7xl mx-auto p-10 bg-white rounded-lg">
      
      <p className="text-sm text-gray-600 mb-4">Son Güncelleme Tarihi: 30.08.2024</p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Giriş: Bilgilerinizi Nasıl Koruyoruz?</h2>
        <p>
          Bu Gizlilik Politikası, Türkiye Etkinlikleri ("Biz", "Bize", "Bizim") tarafından işletilen <a href="https://turkiyetkinlikleri.com" className='font-bold text-cyan-500'>turkiyetkinlikleri.com</a> ("Web Sitemiz") üzerinde kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı, paylaştığımızı ve koruduğumuzu açıklamaktadır. Web Sitemiz, Türkiye'deki etkinlikler hakkında kapsamlı bilgi sunmak amacıyla tasarlanmıştır. Bu politikayı okuyarak, kişisel verilerinizin nasıl işlendiğini ve korunduğunu öğrenebilirsiniz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Topladığımız Bilgiler</h2>
        <ul className="list-disc list-inside">
          <li><strong>Kişisel Bilgiler:</strong> Kullanıcılarımız tarafından gönüllü olarak sağlanan, isim ve e-posta adresi gibi kişisel veriler.</li>
          <li><strong>Kullanım Verileri:</strong> Web Sitemiz üzerindeki etkileşimleriniz, IP adresiniz, tarayıcı türünüz, erişim tarihleri ve saatleri gibi kullanım bilgileri.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Bilgi Kullanımı: Verilerinizi Nasıl Kullanıyoruz?</h2>
        <ul className="list-disc list-inside">
          <li>Web Sitemizi ve hizmetlerimizi sağlamak ve sürdürmek.</li>
          <li>Kullanıcı deneyimini kişiselleştirmek ve iyileştirmek.</li>
          <li>Etkinlikler hakkında bilgi ve güncellemeleri kullanıcılarımızla paylaşmak.</li>
          <li>Yasal yükümlülüklere uymak ve kullanıcı taleplerine yanıt vermek.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Bilgi Paylaşımı: Verilerinizi Kiminle Paylaşıyoruz?</h2>
        <ul className="list-disc list-inside">
          <li><strong>Hizmet Sağlayıcıları:</strong> Web Sitemizin işletilmesi için gerekli hizmetleri sunan güvenilir üçüncü taraflarla.</li>
          <li><strong>Yasal Gereklilikler:</strong> Yasal süreçlere uymak ve yasal yükümlülüklerimizi yerine getirmek için.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Çerezler ve İzleme Teknolojileri</h2>
        <p>
          Web Sitemiz, kullanıcı deneyimini iyileştirmek amacıyla çerezler ve diğer izleme teknolojilerini kullanabilir. Çerezler, tarayıcınıza yerleştirilen küçük veri dosyalarıdır. Çerezleri tarayıcı ayarlarınızdan yönetebilir veya devre dışı bırakabilirsiniz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Güvenlik: Bilgilerinizi Nasıl Koruyoruz?</h2>
        <p>
          Kişisel bilgilerinizi korumak için uygun güvenlik önlemleri alıyoruz. Ancak, internet üzerinden iletilen bilgilerin tam güvenliğini garanti edemeyiz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Kullanıcı Hakları: Verilerinize Erişim ve Kontrol</h2>
        <p>
          Kişisel verilerinize erişim hakkına sahipsiniz. Kişisel bilgilerinizin düzeltilmesini, silinmesini veya işlenmesinin sınırlandırılmasını talep edebilirsiniz. Bu taleplerinizi <a href="mailto:turkiyetkinlikleri@gmail.com" className="font-bold text-cyan-500">turkiyetkinlikleri@gmail.com</a> üzerinden bize iletebilirsiniz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Üçüncü Taraf Bağlantıları</h2>
        <p>
          Web Sitemiz, üçüncü taraf sitelere bağlantılar içerebilir. Bu sitelerin gizlilik uygulamalarından sorumlu değiliz. Bu tür sitelere kişisel bilgilerinizi paylaşmadan önce gizlilik politikalarını kontrol etmenizi öneririz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Politika Değişiklikleri</h2>
        <p>
          Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Politika değişikliklerini Web Sitemizde yayımlayarak bilgilendireceğiz. Değişiklikler, yayımlandıkları tarihten itibaren geçerli olacaktır.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">10. İletişim: Sorularınız ve Destek</h2>
        <p>
          Gizlilik politikamız hakkında herhangi bir sorunuz varsa, bize şu şekilde ulaşabilirsiniz:
        </p>
        <ul className="list-disc list-inside">
          <li><strong>E-posta:<a href="mailto:turkiyetkinlikleri@gmail.com" className="font-bold text-cyan-500"> turkiyetkinlikleri@gmail.com</a></strong></li>
        </ul>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
