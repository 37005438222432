import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <main className="grid min-h-full h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
  <div className="text-center">
    <p className="text-base font-semibold text-cyan-500">404</p>
    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Sayfa bulunamadı</h1>
    <p className="mt-6 text-base leading-7 text-gray-600">Üzgünüz, aradığınız sayfayı bulamadık.</p>
    <div className="mt-10 flex items-center justify-center gap-x-6">
      <Link to={'/'} className="rounded-full bg-cyan-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500">Ana Sayfaya Dön</Link>      
    </div>
  </div>
</main>
  )
}

export default NotFound