const faqData = [
    {
      question: "Türkiye'de popüler etkinlikler nelerdir?",
      answer: "Türkiye, müzik festivallerinden sanat sergilerine, spor etkinliklerinden geleneksel festivallere kadar geniş bir etkinlik yelpazesi sunar. Özellikle yaz aylarında, İstanbul, İzmir ve Antalya gibi şehirlerde büyük çaplı etkinlikler düzenlenir. Popüler etkinlikler arasında İstanbul Müzik Festivali, Antalya Film Festivali ve İzmir Enternasyonal Fuarı yer alır.",
      keywords: ["Türkiye etkinlikleri", "popüler etkinlikler", "müzik festivalleri", "sanat sergileri"]
    },
    {
      question: "Etkinlikler hakkında bilgi nereden alınabilir?",
      answer: "Etkinlikler hakkında güncel bilgi almak için yerel etkinlik sitelerini, sosyal medya hesaplarını ve etkinlik uygulamalarını takip edebilirsiniz. Ayrıca, büyük şehirlerin resmi web siteleri ve şehir rehberleri de etkinlik bilgilerini sağlar. Özellikle bilet satın alırken güvenilir kaynaklardan bilgi almak önemlidir.",
      keywords: ["etkinlik bilgisi", "etkinlik siteleri", "sosyal medya etkinlikleri", "şehir rehberleri"]
    },
    {
      question: "Türkiye'de aile etkinlikleri için en iyi yerler nerelerdir?",
      answer: "Türkiye'de aile etkinlikleri için en iyi yerler arasında eğlence parkları, doğa yürüyüş parkurları ve su parkları bulunur. Çocuklar için uygun mekanlar arasında Antalya'daki Su Parkı, İzmir'deki Tema Park ve İstanbul'daki Miniatürk yer alır. Bu mekanlar aileler için keyifli ve güvenli bir ortam sunar.",
      keywords: ["aile etkinlikleri Türkiye", "temalı parklar", "doğa yürüyüş parkurları", "eğlence merkezleri"]
    },
    {
      question: "Etkinlikler için bilet alırken nelere dikkat edilmelidir?",
      answer: "Etkinlik biletleri satın alırken, etkinliğin tarih ve saatini kontrol etmek, biletlerin geçerlilik koşullarını okumak ve güvenilir bilet satıcılarını tercih etmek önemlidir. Ayrıca, biletlerin fiyatlarını karşılaştırmak ve resmi bilet satış noktalarından almak dolandırıcılık riskini azaltır.",
      keywords: ["etkinlik biletleri", "bilet alırken dikkat edilmesi gerekenler", "bilet fiyatları", "güvenilir bilet satıcıları"]
    },
    {
      question: "Türkiye'de etkinlikler için en iyi zaman nedir?",
      answer: "Türkiye'de etkinlikler için en ideal zaman genellikle bahar ve yaz aylarıdır. Bu dönemlerde hava koşulları mükemmel olup açık hava etkinlikleri için uygundur. Yaz aylarında çeşitli festivaller ve konserler düzenlenir. Kış aylarında ise kapalı mekan etkinlikleri ve kültürel organizasyonlar öne çıkar.",
      keywords: ["Türkiye etkinlikleri zaman", "en iyi etkinlik zamanı", "bahar etkinlikleri", "yaz festivalleri"]
    }
  ];
  
  export default faqData;