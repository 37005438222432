import React, { useEffect, useState, useRef } from "react";
import { collection, getDocs, onSnapshot, query, orderBy, doc, getDoc, updateDoc } from "firebase/firestore";
import { Link, useNavigate, } from "react-router-dom";
import { db } from "../../firebase/Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Slider from "../slider/Slider";
import istanbulpp from "../../files/istanbul-pp.jpg"
import istanbul from "../../files/istanbul.png"
import Antalya from "../../files/Antalya.jpg"
import Footer from "../footer/Footer";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// import toast from "react-hot-toast";
import '../../App.css'

const ArtistsList = () => {
  const navigate = useNavigate();
  const [artists, setArtists] = useState([]);
  const [mostClicked, setMostClicked] = useState([]);
  // const [mostSearched, setMostSearched] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [, setUser] = useState(null);
  const [, setIsAdmin] = useState(false);
  const [filter, setFilter] = useState({
    name: "",
    minRating: 0
  });
  const [loading, setLoading] = useState(true);
  const [isAtStartMostClicked, setIsAtStartMostClicked] = useState(true);
  const [isAtEndMostClicked, setIsAtEndMostClicked] = useState(false);
  const [isAtStartMostSearched, setIsAtStartMostSearched] = useState(true);
  const [isAtEndMostSearched, setIsAtEndMostSearched] = useState(false);
  // const navigate = useNavigate();
  const mostClickedRef = useRef(null);
  const mostSearchedRef = useRef(null);


  const slides = [
    { image: istanbul, alt: 'Slide 1' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/kastel-istanbul-23716.jpg', alt: 'Slide 2' },
    { image: 'https://cdn.bubilet.com.tr/files/EtiketBanner/zorlu-psm-turkcell-sahnesi-98391.jpg', alt: 'Slide 3' },
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        const userRef = doc(db, "users", currentUser.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists() && userDoc.data().role === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Kullanıcı rolü alınırken bir hata oluştu:", error);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });

    const artistsCollection = collection(db, "artists");
    const q = query(artistsCollection, orderBy("createdAt", "desc"));
    const unsubscribeArtists = onSnapshot(q, async (snapshot) => {

      const artistList = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const artistData = { id: doc.id, ...doc.data() };

          const commentsCollection = collection(db, "artists", doc.id, "comments");
          const commentsSnapshot = await getDocs(commentsCollection);
          const ratings = commentsSnapshot.docs.map((doc) => doc.data().rating);
          const averageRating =
            ratings.length > 0
              ? (ratings.reduce((acc, rating) => acc + rating, 0) / ratings.length).toFixed(1)
              : 0;

          return { ...artistData, averageRating };
        })
      );

      setArtists(artistList);
      setFilteredArtists(artistList);
      setMostClicked([...artistList].sort((a, b) => b.clickCount - a.clickCount).slice(0, 15));



      setLoading(false);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeArtists();
    };
  }, []);

  useEffect(() => {
    const filtered = artists.filter(artist => {
      return (
        artist.name.toLowerCase().includes(filter.name.toLowerCase())
      );
    });
    setFilteredArtists(filtered);
  }, [filter, artists]);

  const handleScroll = () => {
    if (mostClickedRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = mostClickedRef.current;
      setIsAtStartMostClicked(scrollLeft === 0);
      setIsAtEndMostClicked(scrollWidth - scrollLeft <= clientWidth);
    }

    if (mostSearchedRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = mostSearchedRef.current;
      setIsAtStartMostSearched(scrollLeft === 0);
      setIsAtEndMostSearched(scrollWidth - scrollLeft <= clientWidth);
    }
  };

  useEffect(() => {
    const containerMostClicked = mostClickedRef.current;

    const containerMostSearchedRef = mostSearchedRef.current;

    const handleScrollEvent = () => {
      handleScroll();
    };

    if (containerMostClicked) {
      containerMostClicked.addEventListener('scroll', handleScrollEvent);
    }

    if (containerMostSearchedRef) {
      containerMostSearchedRef.addEventListener('scroll', handleScrollEvent);
    }

    handleScrollEvent(); // İlk kontrol

    return () => {
      if (containerMostClicked) {
        containerMostClicked.removeEventListener('scroll', handleScrollEvent);
      }
      if (containerMostSearchedRef) {
        containerMostSearchedRef.removeEventListener('scroll', handleScrollEvent);
      }

    };
  }, [artists, filter]);


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // const handleDeleteArtist = async (artistId) => {
  //   try {
  //     await deleteDoc(doc(db, "artists", artistId));
  //     toast.success("Sanatçı başarıyla silindi.");
  //   } catch (error) {
  //     toast.error("Sanatçı silinirken bir hata oluştu:", error);
  //   }
  // };

  const handleArtistClick = async (artistId) => {
    try {
      const artistRef = doc(db, "artists", artistId);
      const artistDoc = await getDoc(artistRef);
      if (artistDoc.exists()) {
        const newClickCount = (artistDoc.data().clickCount || 0) + 1;
        await updateDoc(artistRef, { clickCount: newClickCount });
      }
    } catch (error) {
      console.error("Sanatçı tıklama sayısı güncellenirken hata oluştu:", error);
    }
  };
  const scrollAmount = 119;
  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  const [, setShowArtists] = useState(false);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setShowArtists(true); // Sanatçılar arama yapıldığında gösterilir
  };

  return (
    <div>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> : <div>
        <div className='mt-14'>
          <Slider slides={slides} />
        </div>
        <div className={`flex overflow-hidden bg-gray-200 gap-8 flex-col p-4`}>
          <h1 className="text-3xl font-bold text-center mt-2">Sanatçılar</h1>
          <div className="p-4 w-full  lg:max-w-7xl rounded-xl bg-white box-border overflow-hidden mx-auto">
            <h2 className="text-xl font-bold ml-[7px]">Sanatçı Ara</h2>
            <div className="h-[3px] w-14 rounded-full bg-cyan-500 ml-[7px] mb-5 mt-3"></div>
            <div onSubmit={handleSearchSubmit}>
              <input
                type="text"
                name="name"
                value={filter.name}
                onChange={handleFilterChange}
                placeholder="Sanatçı ismi ara..."
                className=" border-gray-300 p-2 rounded-lg block w-full ml-[7px] border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
              />
            </div>
            <div ref={mostSearchedRef} className="flex overflow-x-auto scroll-container mt-5">
              {filteredArtists.map((artist) => (
                <Link to={`/artist/${artist.id}`} key={artist.id} className="flex-shrink-0 border rounded-xl overflow-hidden mx-[6px] bg-white">
                  <div className="flex overflow-hidden">
                    <img
                      src={artist.imageUrl}
                      onClick={() => handleArtistClick(artist.id)}
                      alt={artist.name}
                      className="w-[105px] h-[105px] p-[15px] object-cover rounded-full hover:cursor-pointer transition-transform transform"
                    />
                  </div>
                  <div className={`px-2 pb-[14px] -mt-2 relative`}>
                    <h2 className="text-xs w-full text-center font-medium truncate">{artist.name}</h2>
                    <p className="text-yellow-500 text-center font-medium text-xs">★{artist.averageRating}</p>
                  </div>
                </Link>
              ))}
            </div>
            <div className="flex items-center justify-center mt-4 gap-4">
              <button
                onClick={() => scrollLeft(mostSearchedRef)}
                className={`bg-cyan-500 text-white flex items-center justify-center rounded-full h-8 w-8 shadow hover:bg-cyan-600 transition-colors duration-300 ${isAtStartMostSearched ? 'opacity-50 cursor-not-allowed' : ''}`}
                aria-label="Geri Kaydır"
                disabled={isAtStartMostSearched}
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={() => scrollRight(mostSearchedRef)}
                className={`bg-cyan-500 text-white flex items-center justify-center rounded-full h-8 w-8 shadow hover:bg-cyan-600 transition-colors duration-300 ${isAtEndMostSearched ? 'opacity-50 cursor-not-allowed' : ''}`}
                aria-label="İleri Kaydır"
                disabled={isAtEndMostSearched}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>

          {/* En Çok Tıklananlar Bölümü */}
          <div className="p-4 w-full lg:max-w-7xl rounded-xl bg-white box-border overflow-hidden mx-auto">
            <h2 className="text-xl font-bold ml-[7px]">En Çok Tıklananlar</h2>
            <div className="h-[3px] w-14 rounded-full bg-cyan-500 ml-[7px] mb-5 mt-3"></div>
            <div className="flex items-center">

              <div ref={mostClickedRef} className="flex overflow-x-auto scroll-container">
                {mostClicked.map((artist) => (
                  <Link to={`/artist/${artist.id}`} key={artist.id} className="flex-shrink-0 border rounded-xl overflow-hidden mx-[6px] bg-white">
                    <div className="flex overflow-hidden">
                      <img
                        src={artist.imageUrl}
                        onClick={() => handleArtistClick(artist.id)}
                        alt={artist.name}
                        className="w-[105px] h-[105px] p-[15px] object-cover rounded-full hover:cursor-pointer transition-transform transform"
                      />
                    </div>
                    <div className={`px-2 pb-[14px] -mt-2 relative`}>
                      <h2 className="text-xs w-full text-center font-medium truncate">{artist.name}</h2>
                      <p className="text-yellow-500 text-center font-medium text-xs">★{artist.averageRating}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center mt-4 gap-4">
              <button
                onClick={() => scrollLeft(mostClickedRef)}
                className={`bg-cyan-500 text-white flex items-center justify-center rounded-full h-8 w-8 shadow hover:bg-cyan-600 transition-colors duration-300 ${isAtStartMostClicked ? 'opacity-50 cursor-not-allowed' : ''}`}
                aria-label="Geri Kaydır"
                disabled={isAtStartMostClicked}
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={() => scrollRight(mostClickedRef)}
                className={`bg-cyan-500 text-white flex items-center justify-center rounded-full h-8 w-8 shadow hover:bg-cyan-600 transition-colors duration-300 ${isAtEndMostClicked ? 'opacity-50 cursor-not-allowed' : ''}`}
                aria-label="İleri Kaydır"
                disabled={isAtEndMostClicked}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
          <div className="p-4 w-full lg:max-w-7xl rounded-xl bg-white box-border overflow-hidden mx-auto">
            <h2 className="text-xl font-bold ml-[7px]">Şehirler</h2>
            <div className="h-[3px] w-14 rounded-full bg-cyan-500 ml-[7px] mb-5 mt-3"></div>
            <div className="grid grid-cols-12 gap-2">
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/İstanbul") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">İstanbul</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Antalya") }}><img src={Antalya} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Antalya</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Ankara") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Ankara</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/İzmir") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">İzmir</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Muğla") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Muğla</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Bursa") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Bursa</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Adana") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Adana</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Gaziantep") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Gaziantep</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Konya") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Konya</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Trabzon") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Trabzon</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Mersin") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Mersin</span></div>
              <div className="w-full rounded-xl border hover:cursor-pointer" onClick={() => { navigate("/sehirler/Kayseri") }}><img src={istanbulpp} className="h-28  w-full rounded-t-xl object-cover" alt=""/><span className="justify-center p-2 flex w-full text-xs font-medium">Kayseri</span></div>
            </div>
          </div>
          {/* En Çok Arananlar Bölümü */}
          {/* <div className="p-4 max-w-7xl mx-auto mt-6">
          <h2 className="text-2xl font-bold">En Çok Arananlar</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => scrollLeft(mostSearchedRef)}
              className={`bg-blue-500 text-white rounded-full h-8 w-8 shadow hover:bg-blue-600 transition-colors duration-300 ${isAtStartMostSearched ? 'opacity-50 cursor-not-allowed' : ''}`}
              aria-label="Geri Kaydır"
              disabled={isAtStartMostSearched}
            >
              Geri
            </button>
            <div ref={mostSearchedRef} className="flex overflow-x-auto space-x-3">
              {mostSearched.map((artist) => (
                <Link to={`/artist/${artist.id}`} key={artist.id} className="flex-shrink-0 border rounded-xl overflow-hidden bg-white">
                  <div className="flex overflow-hidden">
                    <img
                      src={artist.imageUrl}
                      onClick={() => handleArtistClick(artist.id)}
                      alt={artist.name}
                      className="w-28 h-28 p-4 object-cover rounded-full hover:cursor-pointer transition-transform transform hover:scale-105"
                    />
                  </div>
                  <div className={`px-2 pb-[14px] -mt-2 relative`}>
                    <h2 className="text-xs w-full text-center font-medium truncate">{artist.name}</h2>
                    <p className="text-yellow-500 text-center font-medium text-xs">★{artist.averageRating}</p>
                  </div>
                </Link>
              ))}
            </div>
            <button
              onClick={() => scrollRight(mostSearchedRef)}
              className={`bg-blue-500 text-white rounded-full h-8 w-8 shadow hover:bg-blue-600 transition-colors duration-300 ${isAtEndMostSearched ? 'opacity-50 cursor-not-allowed' : ''}`}
              aria-label="İleri Kaydır"
              disabled={isAtEndMostSearched}
            >
              İleri
            </button>
          </div>
        </div> */}
        </div>
        <Footer />
      </div>}
    </div>
  );
};

export default ArtistsList;
