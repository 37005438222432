import { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/Firebase';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

import React from 'react';

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emailFilter, setEmailFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedRole, setEditedRole] = useState('');
  const [loading, setLoading]=useState(true)

  


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true); // Yüklenmeye başla
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
        setFilteredUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Yükleme tamamlandı
      }
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const filtered = users.filter(user => {
      return (
        user.email.toLowerCase().includes(emailFilter.toLowerCase()) &&
        user.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (roleFilter ? user.role === roleFilter : true)
      );
    });
    setFilteredUsers(filtered);
  }, [emailFilter, nameFilter, roleFilter, users]);


  const handleEdit = (user) => {
    setCurrentUser(user);
    setEditedName(user.name);
    setEditedRole(user.role);
    setShowPopup(true);
  };

  const handleUpdate = async () => {
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.id);

    try {
      await updateDoc(userDocRef, {
        name: editedName,
        role: editedRole
      });
      setUsers(users.map(user =>
        user.id === currentUser.id ? { ...user, name: editedName, role: editedRole } : user
      ));
      setFilteredUsers(filteredUsers.map(user =>
        user.id === currentUser.id ? { ...user, name: editedName, role: editedRole } : user
      ));
      toast.success('Kullanıcı bilgileri başarıyla güncellendi.');
    } catch (error) {
      toast.error('Güncelleme sırasında bir hata oluştu.');
    }
    setCurrentUser(null);
    setShowPopup(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  return (
    <div className="container mx-auto">
      {loading ? (<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>): <div className="space-y-4"><div className="space-y-4">
        <div className="flex justify-start items-center gap-2">
          <input
            type="text"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
            placeholder="E-posta ile filtrele..."
            className="block w-52 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
          />
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            placeholder="İsim ile filtrele..."
            className="block w-52 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
          />
          <select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
            className="block w-52 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
          >
            <option value="">Tüm Rolleri Göster</option>
            <option value="admin">Admin</option>
            <option value="user">Kullanıcı</option>
          </select>
        </div>

        {filteredUsers.map((user) => (
          <div 
            key={user.id} 
            className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div>
              <p className="text-gray-800 font-semibold">{user.email}</p>
              <p className="text-sm text-gray-600">ID: {user.id}</p>
              <p className="text-sm text-gray-600">Name: {user.name}</p>
              <p className="text-sm text-gray-600">Role: {user.role}</p>
              <p className="text-sm text-gray-600">Created At: {formatDate(user.createdAt)}</p>
            </div>
            <div className="flex">
              <button 
                onClick={() => handleEdit(user)}
                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors text-sm"
              >
                Düzenle
              </button>
             
            </div>
          </div>
        ))}
      </div>

      {showPopup && (
        <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-96 p-6 rounded-lg shadow-lg">
            {currentUser ? (
              <div>
                <h2 className="text-lg font-semibold mb-4">Kullanıcı Bilgilerini Düzenle</h2>
                <div className='space-y-4'>
               <div>
               <label htmlFor="editname" className="block text-sm font-medium text-gray-700">
                            İsim
                          </label>
                  <input
                  id='editname'
                    type="text"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    placeholder="Yeni isim..."
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
               </div>
                    <div>
                    <label htmlFor="editrole" className="block text-sm font-medium text-gray-700">
                            Yetki
                          </label>
                  <select
                  id='editrole'
                    value={editedRole}
                    onChange={(e) => setEditedRole(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                    <option value="admin">Admin</option>
                    <option value="user">Kullanıcı</option>
                  </select>
                    </div>
                </div>
                <hr className='mt-4 mb-4'/>
                <div className="flex gap-2 justify-end">
                  <button 
                    onClick={() => setShowPopup(false)}
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                    >
                    Kapat
                  </button>
                  <button 
                    onClick={handleUpdate}
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    >
                    Güncelle
                  </button>
                  
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      )}</div>}
    </div>
  );
}

export default Users;
